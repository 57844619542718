import { DatePicker, Select, Modal, message, Divider, Button } from "antd";
import moment from "moment";
import React from "react";
import service from "../../../common/service";
import ListCard from "./ListCard/ListCard";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { isDesktop, isMobile } from 'react-device-detect';
import "./PurchaseDetails.scss";

const currencyFormatter = require('currency-formatter');
const { confirm } = Modal;
const dateFormat = {
    "month": "MMMM",
    "date": "DD/MM/YYYY",
}

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            customers_list: [],
            products_list: [],
            datePicker: "month"
        }
    }

    handleDateFilter(date, dateString) {
        let monthStart = moment(date).startOf(this.state.datePicker).toISOString();
        let monthEnd = moment(date).endOf(this.state.datePicker).toISOString();
        this.setState({
            selectedMonth: moment(date).format(dateFormat[this.state.datePicker]),
            selectedDateValue: date,
            monthStart,
            monthEnd
        })
    }
    handleCustomerFilter(val) {
        this.setState({
            customer_name: val
        })
    }
    handleProductsFilter(val) {
        this.setState({
            selected_product: val
        })
    }

    handleStatusFilter(val) {
        this.setState({
            status: val
        })
    }

    handleApplyFilter() {
        this.getPurchaseDetails([
            {column: "product_name", value: this.state.selected_product ? [this.state.selected_product] : []},
            {column: "customer_name", value: this.state.customer_name ? [this.state.customer_name] : []}, 
            {column: "purchase_date", value: [this.state.monthStart, this.state.monthEnd], isDate: true }
        ])
    }

    changeDatePicker(val) {
        if(val) {
            
            this.setState({
                datePicker: val
            })
        }
    }

    getPurchaseDetails(filter, flag) {
        if(flag) {
            let monthStart = moment().startOf(this.state.datePicker).toISOString();
            let monthEnd = moment().endOf(this.state.datePicker).toISOString();
            this.setState({
                monthStart,
                monthEnd,
                selectedMonth: moment().format(dateFormat[this.state.datePicker]),
                selectedDateValue: moment(),
                selected_product: null,
                customer_name: null,
                status: null
            })
        }  
        service.GET("get_purchase_list", filter).then(res => {
            let total = 0, totalAmount = 0, gstAmount = 0, amountPaid = 0, pendingAmount = 0;
            res.forEach(m => {
                total += m.total && Number(m.total) || 0;
                totalAmount += m.total_amount && Number(m.total_amount) || 0;
                gstAmount += m.gst_amount && Number(m.gst_amount) || 0;
                amountPaid += m.amount_paid && Number(m.amount_paid) || 0;
                pendingAmount += m.pending_amount && Number(m.pending_amount) || 0;
            });

            this.setState({
                total, totalAmount, gstAmount, amountPaid, pendingAmount,
                data: res,
                filteredData: this.state.status == "pending" ? res.filter(m => m.pending_amount && Number(m.pending_amount) > 0) : res
            })
        })
    }

    deletePurchase(id, close) {
        service.DELETE("delete_purchase_item", [{column: "_id", value: [id]}])
        .then(res => {
            close();
            message.success("Deleted successfully...")
            this.getPurchaseDetails([{column: "customer_name", value: this.state.customer_name ? [this.state.customer_name] : []}, {column: "purchase_date", value: [this.state.monthStart, this.state.monthEnd], isDate: true }])
        })
    }

    componentDidMount() {
        let monthStart = moment().startOf(this.state.datePicker).toISOString();
        let monthEnd = moment().endOf(this.state.datePicker).toISOString();
        this.setState({
            monthStart, monthEnd
        })
        this.getPurchaseDetails([{column: "purchase_date", value: [monthStart, monthEnd], isDate: true }])
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps !== prevState.props) {
            return {
                props: nextProps,
                ...nextProps
            };
        } else return null;
    }

    showDeleteConfirm(id) {
        confirm({
          title: 'Are you sure delete this item?',
          icon: <ExclamationCircleOutlined />,
          content: '',
          okText: 'Yes',
          okType: 'danger',
          cancelText: 'No',
          onOk: close => {
            this.deletePurchase(id, close)
          },
          onCancel() {
            console.log('Cancelled');
          },
        });
      };

    render() {
        const style = {
            display: isMobile ? "flex":"block"
        }

        return (
            <div className="purchase-details-container">
                <Divider />
                <div className="filter-container" style={{display: isDesktop ? "flex" : "block"}}>
                
                <Select 
                        defaultValue="all"
                        allowClear={true}
                        style={isDesktop ? {width: "100px"} : {width: "100%"}}
                        options={[{label: "All", value: "all"}, {label: "Pending", value: "pending"}]}
                        onChange={this.handleStatusFilter.bind(this)}
                    />
                <Select 
                        defaultValue="month"
                        allowClear={true}
                        style={isDesktop ? { width: "100px", marginLeft: "10px" } : {width: "100%", marginTop: "10px", marginLeft: "unset"}}
                        options={[{label: "Month", value: "month"}, {label: "Date", value: "date"}]}
                        onChange={this.changeDatePicker.bind(this)}
                    />
                    <DatePicker
                        picker={this.state.datePicker}
                        defaultValue={moment()}
                        value={this.state.selectedDateValue || moment()}
                        format={dateFormat[this.state.datePicker]}
                        allowClear={false}
                        style={isDesktop ? { width: "150px", marginLeft: "10px" } : {width: "100%", marginTop: "10px", marginLeft: "unset"}}
                        onChange={this.handleDateFilter.bind(this)}
                    />
                    <Select 
                        placeholder="Select customer"
                        allowClear={true}
                        style={isDesktop ? { width: "200px", marginLeft: "10px" } : {width: "100%", marginTop: "10px"}}
                        options={this.state.customers_list || []}
                        onChange={this.handleCustomerFilter.bind(this)}
                    />
                    <Select 
                        placeholder="Select Product"
                        allowClear={true}
                        style={isDesktop ? { width: "200px", marginLeft: "10px" } : {width: "100%", marginTop: "10px"}}
                        options={this.state.products_list || []}
                        onChange={this.handleProductsFilter.bind(this)}
                    />

                    <Button style={isDesktop ? { background: "#ffb74d", width: "200px", marginLeft: "10px" } : {background: "#ffb74d",width: "100%", marginTop: "10px"}} onClick={this.handleApplyFilter.bind(this)}>Apply</Button>
                    
                </div>
                <div className="overall-summary">
                    <h3>Showing details for {this.state.selectedMonth || moment().format("MMMM")} {this.state.datePicker}</h3>
                    <div style={{display: isMobile ? "block" : "flex"}} className="overall-summary-item-list">
                        <div style={style}><span>Total: </span><span>₹ {this.state.total && currencyFormatter.format(this.state.total, { code: 'INR' }).substr(1) || "0.00"}</span></div>
                        <div style={style}><span>Total GST Amount: </span><span>₹ {this.state.gstAmount && currencyFormatter.format(this.state.gstAmount, { code: 'INR' }).substr(1)  || "0.00"}</span></div>
                        <div style={style}><span>Total Amount: </span><span>₹ {this.state.totalAmount && currencyFormatter.format(this.state.totalAmount, { code: 'INR' }).substr(1)  || "0.00"}</span></div>
                    </div>
                    <div style={{display: isMobile ? "block" : "flex"}} className="overall-summary-item-list amount-paid-pending">
                        <div className="amount-paid-container" style={style}><span>Total Amount Paid: </span><span>₹ {this.state.amountPaid && currencyFormatter.format(this.state.amountPaid, { code: 'INR' }).substr(1)  || "0.00"}</span></div>
                        <div className="amount-pending-container" style={style}><span>Total Pending Amount: </span><span>₹ {this.state.pendingAmount && currencyFormatter.format(this.state.pendingAmount, { code: 'INR' }).substr(1)  || "0.00"}</span></div>
                    </div>
                </div>
                {isMobile ? <Divider /> : null}
                <ListCard 
                    data={this.state.filteredData} 
                    products_list={this.state.products_list} 
                    customers_list={this.state.customers_list}
                    handleDelete={this.showDeleteConfirm.bind(this)}
                    handleEdit={val => typeof this.props.handleEdit == "function" && this.props.handleEdit(val)}
                    />
            </div>
        )
    }
}