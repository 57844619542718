import React, { useEffect, useState } from "react";
import {
    Form,
    Button,
    Typography,
    Row,
    Divider,
    Col,
    Tooltip,
} from "antd";
import { connect } from "react-redux";
import service from "../common/service";
import style from "../css/common.js"
import "../css/common.css"
import loader from "../images/loader.gif"
import LoaderComponent from "./LoaderComponent";
import ModalComponent from "./ModalComponent";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import PdfDownLoader from "./PdfComponent/PdfDownLoader";
import { PDFReader, MobilePDFReader } from 'reactjs-pdf-reader';
import { ToWords } from 'to-words';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import moment from 'moment';
import {
    renderInvoiceNumber,
    renderCustomerDetails,
    renderProductDetails,
    renderGSTDetails,
    renderOtherDetails,
    renderReceiverDetails
} from "./Components";
import { isMobile } from "react-device-detect";
import DateTimePicker from "./TimePicker";
import TicketComponent from "./TicketComponent";

const FileSaver = require('file-saver');

const { Title } = Typography;
const toWords = new ToWords();

const handleInvoiceSubmit = (data, state, setState) => {
    let invoiceData = data || {};
    let customer_details = {
        customer_id: invoiceData.customer_id, customer_address: service.titleCase(invoiceData.customer_address), customer_email: invoiceData.customer_email,
        customer_gst_number: invoiceData.customer_gst_number, customer_msme_number: invoiceData.customer_msme_number, customer_order_number: invoiceData.customer_order_number && invoiceData.customer_order_number.toUpperCase(),
        customer_phone: invoiceData.customer_phone, place_of_supply: invoiceData.place_of_supply, state_code: invoiceData.state_code
    }

    let customerData = state.customerList || [];
    customerData.forEach(m => {
        if (m._id == customer_details.customer_id) {
            customer_details.customer_name = m.customer_name.toUpperCase();
        }
    });

    let products = state.selectedProductList || [], productData = state.productList || [];

    if(!products || !products?.length) {
        alert("Add atleast one product");
        return 0
    }

    let product_details = products.map(m => {
        let obj = {};
        productData.forEach(n => {
            if (m.product_id == n._id) {
                obj = Object.assign(n, m)
            }
        });

        obj.product_name = `${obj.product_length} X ${obj.product_width} X ${obj.product_height}`;
        obj.product_total_price = obj.product_cft ? (Number(obj.quantity) * Number(obj.product_price) * Number(obj.product_cft)).toFixed(2) : (Number(obj.quantity) * Number(obj.product_price)).toFixed(2);
        obj.product_total_cft = (Number(obj.quantity) * Number(obj.product_cft)).toFixed(2);
        return obj;
    })

    let receiver_details = {
        eway_number: invoiceData.eway_number && invoiceData.eway_number.toUpperCase(),
        receiver_name: invoiceData.receiver_name && invoiceData.receiver_name.toUpperCase(), receiver_phone: invoiceData.receiver_phone && invoiceData.receiver_phone.toUpperCase(),
        receiver_dl: invoiceData.receiver_dl && invoiceData.receiver_dl.toUpperCase(), receiver_rc_number: invoiceData.receiver_rc_number && invoiceData.receiver_rc_number.toUpperCase()
    }

    let gst_details = { cgst: invoiceData.cgst, sgst: invoiceData.sgst, igst: invoiceData.igst };

    let material_details = (invoiceData.material_details || []).reduce((acc, item) => {
        acc[item] = true
        return acc;
    }, {});

    let summary_details = {};
    let total = 0, total_quantity = 0, total_cft = 0;
    product_details.forEach(item => {
        total += Number(item.product_total_price);
        total_quantity += Number(item.quantity);
        total_cft += Number(item.product_total_cft);

    });

    summary_details.gross_total = total;
    summary_details.total_quantity = total_quantity;
    summary_details.total_cft = total_cft.toFixed(2);
    summary_details.enableCFT = state.enableCFT;

    let selectedState = customer_details?.state_code?.split(" ")?.[0];

    if (summary_details["gross_total"] && summary_details["gross_total"] != "NaN" && selectedState == "33") {
        summary_details["cgst_value"] = parseFloat(((summary_details["gross_total"] * Number(gst_details.cgst)) / 100).toFixed(2));
        summary_details["sgst_value"] = parseFloat(((summary_details["gross_total"] * Number(gst_details.sgst)) / 100).toFixed(2));
        summary_details["igst_value"] = 0;
        summary_details["total_amount"] = Math.ceil(Number(summary_details["cgst_value"]) + Number(summary_details["sgst_value"]) + Number(summary_details["gross_total"]));
        summary_details["amount_in_words"] = toWords.convert(summary_details["total_amount"], { currency: true });
    } else if (summary_details["gross_total"] && summary_details["gross_total"] != "NaN") {
        summary_details["cgst_value"] = 0;
        summary_details["sgst_value"] = 0;
        summary_details["igst_value"] = parseFloat(((summary_details["gross_total"] * Number(gst_details.igst)) / 100).toFixed(2));
        summary_details["total_amount"] = Math.ceil(Number(summary_details["igst_value"]) + Number(summary_details["gross_total"]));
        summary_details["amount_in_words"] = toWords.convert(summary_details["total_amount"], { currency: true });
    }

    let pdfInfo = { invoice_date: moment(invoiceData.invoice_date, 'L'), invoice_number: invoiceData.invoice_number, current_date: invoiceData.current_date, status: "Draft", customer_details, product_details, receiver_details, gst_details, material_details, summary_details };
    console.log(pdfInfo, "pdfinfo")
    let current_date = moment(), annual_start_date = moment(`04-01-${moment().year()}`), annual_end_date = moment(`03-30-${moment().year() + 1}`);
    if (current_date.valueOf() > annual_start_date.valueOf()) {
        pdfInfo.annual_year = `${moment().year()}-${moment().year() + 1}`;
        pdfInfo.formatted_invoice_number = pdfInfo.invoice_number + "/" + pdfInfo.annual_year
    }
    setState(() => ({
        ...state,
        modalOpen: true,
        pdfInfo,
        openPdf: true
    }))
}

const renderInvoiceForm = ( state, setState, form, productForm) => {
    return (
        <>
            <div className="invoice-form" style={{ padding: isMobile ? "0px 20px" : "0px 0px 0px 45px" }}>
                <div style={{width: "100%", display: "flex", justifyContent: "space-between"}}>
                <Title level={4} style={{ display: "flex", justifyContent: "flex-start", marginBottom: "25px" }}>INVOICE FORM</Title>
                <Row style={{ display: "flex", marginTop: "20px", justifyContent: "center" }}>
                <Col span={24}>
                    <Form.Item style={style.PopUpAddButton} className="preview-btn">
                        <Tooltip title={state.isInvoiceNumberExist ? "Invoice number already exists" : "Click to preview invoice"}>
                            <Button disabled={state.isInvoiceNumberExist} style={{ ...style.button, width: "unset", padding: "5px 24px" }} onClick={e => form.submit()} >{"Preview"}</Button>
                        </Tooltip>
                    </Form.Item>
                </Col>
            </Row>
                </div>
                <Form name="invoice" form={form}
                    layout="horizontal"
                    scrollToFirstError
                    onFinish={(data) => { handleInvoiceSubmit(data, state, setState) }}
                    initialValues={{
                        cgst: 6,
                        sgst: 6,
                        igst: 18,
                    }}
                    style={{ height: "auto", overflow: "auto" }}
                    autoComplete="off">
                    <Row>
                        <Col span={24}>
                            {renderInvoiceNumber(state, setState)}
                        </Col>
                    </Row>
                    <Divider orientation="left" >Other Details</Divider>
                    <Row>
                        <Col span={24}>
                            {renderOtherDetails(form, state, setState)}
                        </Col>
                    </Row>
                    <Divider orientation="left" >Customer Details</Divider>
                    <Row>
                        <Col span={24}>
                            {renderCustomerDetails(form, state, setState)}
                        </Col>
                    </Row>
                    <Divider orientation="left" >GST Details</Divider>
                    <Row>
                        <Col span={24}>
                            {renderGSTDetails(form, state, setState)}
                        </Col>
                    </Row>
                    
                    <Divider orientation="left" >Product Details</Divider>
                    <Row>
                        <Col span={24}>
                            {renderProductDetails(productForm, state, setState)}
                        </Col>
                    </Row>
                    
                    {/* <Divider orientation="left" >Receiver Details</Divider>
                    <Row>
                        <Col span={24}>
                            {renderReceiverDetails(form, state, setState)}
                        </Col>
                    </Row> */}


                </Form>

            </div>
            
        </>
    )
}

const InvoiceComponent = (props) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    const current_date = `${dd}/${mm}/${yyyy}`

    const [state, setState] = useState({
        customerList: [],
        productList: [],
        refresh: true,
        pdfScale: 0,
        mainData: [{
            label: "Patient Name",
            value: "Saravana Kumar",
            icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png',
            hasSelect: false,
        },
        {
            label: "Mobile Number",
            value: "8220056524",
            icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png',
            hasSelect: false,
            align: "right"
        },
        {
            label: "Consulting Doctor",
            value: [{
                label: "Doctor 1",
                value: "Doctor 1"
            },
            {
                label: "Doctor 2",
                value: "Doctor 2"
            }],
            icon: 'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png',
            hasSelect: true,
            defaultValue: ["Doctor 1"]
        },
        {
            label: "action",
            showLabel: false,
            value: [{
                label: "Doctor 1",
                value: "Doctor 1"
            },
            {
                label: "Doctor 2",
                value: "Doctor 2"
            }],
            hasSelect: true,
            defaultValue: ["Doctor 1"]
        }]
    });

    const [invoiceNumber, setInvoiceNumber] = useState();
    const [refresh, setRefresh] = useState(true);
    const [form] = Form.useForm();
    const [productForm] = Form.useForm();

    useEffect(() => {
        

        let current_date = moment(), annual_start_date = moment(`04-01-${moment().year()}`), annual_end_date = moment(`03-30-${moment().year() + 1}`);
        let filter = [];
        if(current_date.valueOf() > annual_start_date.valueOf()) {
            filter = [annual_start_date.valueOf(), annual_end_date.valueOf()];
        }

        service.DISTINCT({
            indexName: "sales_report_index", 
            groupby: { column: "invoice_number", value: "_id", orderby: "desc" }, 
            projection: ["invoice_number", "_id", "annual_year"], 
            filters: [{column: "timestamp", value: filter, isDate: true}]
        }).then((data, err) => {
            if (data) {
                data = service.sortByKey(data, "invoice_number", "desc");
                service.GET("get_customer_data", [], {}).then(res => {
                    setState(() => ({
                        ...state,
                        invoice_number_list: data,
                        customerList: res && res.length ? res : []
                    }))
                }).catch(err => {
                    console.log(err)
                });
                form.setFieldsValue({
                    invoice_number: data[0] && data[0]["invoice_number"] && Number(data[0]["invoice_number"]) + 1 || 1,
                    invoice_date: moment(current_date, 'DD/MM/YYYY')
                });
            }
        });

    }, [state.refresh]);

    const handleModalClose = () => {
        setState(() => ({
            ...state,
            openPdf: false,
            modalOpen: false,
            pdfScale: 0
        }))
    }

    return (
        <div className="invoice-component-container" style={{ width: "100%" }}>
            {/* {renderTimePicker()} */}
            {renderInvoiceForm(state, setState, form, productForm)}

            {state.openPdf ? <PDFDownloadLink document={<PdfDownLoader {...state} />} fileName={`${state.invoice_number}_${current_date}.pdf`} >
                {({ blob, url, loading, error }) => {
                    if (blob && url) {
                        return (<ModalComponent visible={state.modalOpen} onClose={handleModalClose} style={{ top: "10px", width: "100%" }} children={
                            <div className="pdf-container" >
                                <div style={{ height: "430px", overflow: "auto" }}>
                                    {isMobile ? <MobilePDFReader style={{ display: "flex" }} url={url} scale={"auto"} isShowHeader={false} maxScale={5} isShowFooter={true} onDocumentComplete={pages => {
                                        setState(() => ({
                                            ...state,
                                            blobUrl: url,
                                            blob: blob
                                        }))
                                    }} /> : <PDFReader url={url} width={850} onDocumentComplete={pages => {
                                        setState(() => ({
                                            ...state,
                                            blobUrl: url,
                                            blob: blob
                                        }))
                                    }} />}
                                </div>

                                <div className="pdf-options" style={{ display: "flex", justifyContent: "center", margin: "10px" }}>
                                    <Button style={{ ...style.button, margin: "5px" }} onClick={e => handleCancel(state, setState, form)} >{"Cancel"}</Button>
                                    <Button style={{ ...style.button, margin: "5px" }} onClick={e => handleEdit(state, setState)} >{"Edit"}</Button>
                                    <Button style={{ ...style.button, margin: "5px" }} onClick={e => handleDownload(state, setState, form)} >{"Download"}</Button>
                                </div>
                            </div>
                        } title={"Invoice Preview"} />)
                    } else {
                        return (
                            <div className="loading" style={{ ...style.printLoader }}>
                                <img src={loader} style={{ width: "45px", height: "45px" }} />
                            </div>
                        )
                    }
                }
                }
            </PDFDownloadLink> : null}
        </div>
    )
}

const handleDownload = (state, setState, form) => {
    setState(() => ({
        ...state,
        isLoader: true,
    }));

    service.UPDATE("add_sales_data", state.pdfInfo).then(res => {
        if (res)
            setState(() => ({
                ...state,
                openPdf: false,
                modalOpen: false,
                isLoader: false,
                refresh: !state.refresh
            }));
        let { customer_details, invoice_date, invoice_number } = state.pdfInfo;
        let current_date = invoice_date
        if (typeof invoice_date != "string") {
            var date = new Date(invoice_date);
            let dd = String(date.getDate()).padStart(2, '0');
            let mm = String(date.getMonth() + 1).padStart(2, '0');
            let yyyy = date.getFullYear();
            current_date = `${dd}/${mm}/${yyyy}`;
        }
        form.resetFields();
        FileSaver.saveAs(state.blob, `${invoice_number}_${customer_details.customer_name.replaceAll(" ", "_")}(${current_date}).pdf`);
    }).catch(err => {
        console.log(err)
    });

}
const handleCancel = (state, setState, form) => {
    setState(() => ({
        ...state,
        selectedProductList: [],
        openPdf: false,
        modalOpen: false,
        refresh: !state.refresh
    }));
    form.resetFields()
}

const handleEdit = (state, setState) => {
    setState(() => ({
        ...state,
        openPdf: false,
        modalOpen: false,
    }));
}

const mapStateToProps = state => {
    return {
        // productTableData: state.productTableData
    }
};

const mapDispatchToProps = dispatch => ({
    // setProductTableData: data => dispatch(service.fetchProductData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InvoiceComponent);