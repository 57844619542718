import React from "react";
import PurchaseDetails from "./PurchaseDetails/PurchaseDetails";
import PurchaseForm from "./PurchaseForm/PurchaseForm";
import service from "../../common/service";
import moment from "moment";
import { isDesktop, isMobile } from 'react-device-detect';
import "./Purchase.scss"

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

        }

        this.PurchaseDetails = React.createRef();
    }

    getProductsList() {
        service.DISTINCT({indexName: "purchase_products_index", groupby: { column: "product_name", value: "_id", orderby: "desc" }}).then((data, err) => {
            if (data) {
                data = service.sortByKey(data, "product_id", "desc");
                console.log(data)
                this.setState({
                    products_list: data.map(m => ({label: m.product_name, value: m._id}))
                })
            }
        });
    }

    getCustomersList() {
        service.DISTINCT({indexName: "customer_index", groupby: { column: "customer_name", value: "_id", orderby: "desc" }}).then((data, err) => {
            if (data) {
                data = service.sortByKey(data, "product_id", "desc");
                this.setState({
                    customers_list: data.map(m => ({label: m.customer_name, value: m._id}))
                })
            }
        });
    }

    formCallback(str) {
        let monthStart = moment().startOf('month').toISOString();
        let monthEnd = moment().endOf('month').toISOString();
        if(str == "reset") {
            this.setState({
                showEdit: false,
                editObj: {}
            });
            return true
        }
        this.PurchaseDetails?.getPurchaseDetails([{column: "purchase_date", value: [monthStart, monthEnd], isDate: true}], true);
    }

    componentDidMount() {
        this.getCustomersList();
        this.getProductsList();
    }

    handleEdit(val) {
        val.purchase_date = moment(val.purchase_date)
        this.setState({
            showEdit: true,
            editObj: val
        }, () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
        })
    }

    render() {
        return (
            <div className="page-purchase">
                <div style={{display: isMobile ? "block" : "flex" }} className="purchase-wrapper">
                    <PurchaseForm 
                        getProductsList={this.getProductsList.bind(this)} 
                        products_list={this.state.products_list} 
                        customers_list={this.state.customers_list} 
                        handleFunction={this.formCallback.bind(this)}
                        showEdit={this.state.showEdit}
                        editObj={this.state.editObj || {}}
                    />
                    <PurchaseDetails 
                        ref={ref => this.PurchaseDetails = ref}
                        products_list={this.state.products_list} 
                        customers_list={this.state.customers_list} 
                        handleEdit={this.handleEdit.bind(this)}
                        />
                </div>
            </div>
        )
    }
}