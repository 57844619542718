import React from "react";
import { Form, Button, Input, Select, DatePicker, InputNumber, Divider, Space, message } from "antd";
import moment from "moment";
import { MEASUREMENT_UNITS } from "../../../common/StaticJson";
import { DeleteOutlined } from '@ant-design/icons';
import service from "../../../common/service";
import "./PurchaseForm.scss";

const defaultState = {
    newProductName: null,
    product_name: "",
    customer_name: "",
    showEdit: false
};

const formInitialValues = {
    product_name: null,
    product_price: null,
    gst: 0,
    purchase_date: moment(),
    amount_paid: null,
    quantity: null,
    remarks: null,
    customer_name: null
}

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            newProductName: null,
            products_list: [],
            customer_list: []
        }

        this.form = React.createRef();
        this.inputRef = React.createRef()
    }

    submitPurchaseDetails(formData) {
        formData.purchase_date = moment(formData.purchase_date).toISOString();
        formData.product_name = formData.product_name.trim();
        let tempData = { total: this.state.total, total_amount: this.state.total_amount, gst_amount: this.state.gst_amount, pending_amount: this.state.pending_amount, amount_paid: this.state.amount_paid };
        if(this.state.showEdit) {
            tempData["_id"] = this.props.editObj?._id
        }
        service.UPDATE("add_purchase_details", { ...formData, ...tempData }).then(res => {
            if (res.status == 200) {
                message.success(res.message);
                this.form.current?.setFieldsValue(formInitialValues);
                this.setState(defaultState);
                typeof this.props.handleFunction == "function" && this.props.handleFunction();
            }
        }).catch(err => console.log(err))
    }

    addNewProduct(e) {
        service.UPDATE("add_purchase_product", { product_name: this.state.newProductName }).then(res => {

            if (res.status == 200 && !res.exist) {
                this.setState({
                    newProductName: null
                })
                this.props.getProductsList();
                message.success("Prdouct Added successfully")
                setTimeout(() => {
                    this.inputRef.current?.focus();
                }, 0);
            }

        }).catch(err => {
            console.log(err)
        });

        e.preventDefault();
    }

    deletePurchaseProduct(val) {
        service.DELETE("delete_purchase_product", [{ column: "_id", value: val }]).then(res => {
            if (res.status == 200) {
                this.props.getProductsList();
                this.form.current?.setFieldsValue({
                    product_name: null
                });
                message.success("Prdouct deleted successfully")
                setTimeout(() => {
                    this.inputRef.current?.focus();
                }, 0);
            }

        }).catch(err => {
            console.log(err)
        })
    }

    getGST(fields = [], { product_price = 0, gst = 0, quantity = 1, amount_paid = 0 }) {
        let { name = [] } = fields[0] || []
        let finalResult = {};
        if (!isNaN(product_price) && !isNaN(gst) && !isNaN(quantity)) {
            const gstAmount = (product_price * quantity / 100) * gst;
            finalResult.gst_amount = gstAmount?.toFixed(2);
            finalResult.total = (product_price * quantity)?.toFixed(2);
            finalResult.total_amount = ((product_price * quantity) + gstAmount)?.toFixed(2);
            finalResult.amount_paid = parseFloat(amount_paid)?.toFixed(2);
            finalResult.pending_amount = "0.00";

            if (name.includes("product_price") || name.includes("gst") || name.includes("quantity")) {
                this.form.current?.setFieldsValue({
                    amount_paid: finalResult.total_amount
                })
            }
        }

        if (amount_paid && !isNaN(amount_paid) && !isNaN(finalResult.total_amount)) {
            finalResult.pending_amount = (finalResult.total_amount - amount_paid)?.toFixed(2);
        }


        return finalResult;
    }

    onFieldsChange(fields) {
        let formData = this.form?.current.getFieldsValue(true);
        let finalResult = this.getGST(fields, formData)
        this.setState({
            ...formData,
            ...finalResult
        })
    }



    componentDidMount() {
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps !== prevState.props) {
            return {
                props: nextProps,
                ...nextProps,
            };
        } else return null;
    }

    shouldComponentUpdate(nextProps, nextState ) {
        if (nextProps.showEdit != this.state.showEdit) {
            nextProps.showEdit ? this.form.current?.setFieldsValue(nextProps.editObj || {}) : this.form.current?.setFieldsValue(formInitialValues);
            this.setState({
                showEdit: nextProps.showEdit
            })
            return true
        }
        if (nextProps.products_list != this.state.products_list) {
            this.setState({
                products_list: nextProps.products_list
            })
            return true
        }
        if (nextProps.customers_list != this.state.customers_list) {
            this.setState({
                 customers_list: nextProps.customers_list
            })
            return true
        }

        if(this.state != nextState) return true;
    }


    render() {
        return (
            <div className="purchase-form-container">
                <h2>Add Purchase</h2>
                <Form
                    ref={this.form}
                    onFinish={this.submitPurchaseDetails.bind(this)}
                    onFieldsChange={this.onFieldsChange.bind(this)}
                    labelAlign="left"
                    colon={false}
                    layout="vertical"
                    initialValues={this.state.showEdit ? this.props.editObj : {
                        gst: 0,
                        purchase_date: moment()
                    }}
                >
                    <Form.Item
                        name="customer_name"
                        label="Customer Name"
                        rules={[{ required: true, message: "This is Mandatory!" }]}
                    >
                        <Select
                            disabled={this.state.showEdit}
                            placeholder="Select Customer"
                            options={this.state.customers_list || []}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Purchase Date"
                        name="purchase_date"
                        rules={[{ required: true, message: "This is Mandatory!" }]}
                    >
                        <DatePicker disabled={this.state.showEdit} format="DD/MM/YYYY" allowClear={false} />
                    </Form.Item>
                    <Form.Item
                        label="Product Name"
                        name="product_name"
                        rules={[{ required: true, message: "This is Mandatory!" }]}
                    >
                        <Select
                            placeholder="Select Product"
                            showSearch={true}
                            disabled={this.state.showEdit}
                            filterOption={(input, option) => {
                                let { children = [] } = option?.children?.props || {};
                                return children[0]?.toLowerCase()?.includes(input?.toLowerCase())
                            }

                            }
                            dropdownRender={item => {
                                console.log(item)
                                return (
                                    <>
                                        {item}
                                        <Divider style={{ margin: '8px 0' }} />
                                        <Space style={{ padding: '0 8px 4px' }}>
                                            <Input
                                                placeholder="Enter new product name"
                                                style={{ width: "100%" }}
                                                ref={this.inputRef}
                                                disabled={this.state.showEdit}
                                                value={this.state.newProductName || null}
                                                onChange={e => this.setState({ newProductName: e.target.value })}
                                            />
                                            <Button type="text" onClick={this.addNewProduct.bind(this)}>
                                                Add Product
                                            </Button>
                                        </Space>
                                    </>
                                )
                            }}
                        >
                            {
                                (this.state.products_list || []).map(m => (<Select.Option key={m.value} value={m.value}>
                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                        {m.label}
                                        <DeleteOutlined onClick={this.deletePurchaseProduct.bind(this, m.value)} />
                                    </div>
                                </Select.Option>))
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Product Price"
                        name="product_price"
                        rules={[{ required: true, message: "This is Mandatory!" }]}
                    >
                        <InputNumber disabled={this.state.showEdit} placeholder="Enter Price" />
                    </Form.Item>
                    <Form.Item
                        name="quantity"
                        label="Quantity"
                        rules={[{ required: true, message: "This is Mandatory!" }]}
                    >
                        <Input disabled={this.state.showEdit} placeholder="1" addonAfter={<Form.Item name="unit" noStyle>
                            <Select disabled={this.state.showEdit} placeholder="Select unit" options={MEASUREMENT_UNITS} style={{ width: 100 }} />
                        </Form.Item>} />
                    </Form.Item>
                    <Form.Item
                        label="GST"
                        name="gst">
                        <InputNumber disabled={this.state.showEdit} placeholder="Enter GST" />
                    </Form.Item>
                    <Form.Item
                        label="Amount Paid"
                        name="amount_paid"
                        rules={[{ required: true, message: "This is Mandatory!" }]}
                    >
                        <InputNumber min={0} max={this.state.total_amount} placeholder="Enter paid amount" />
                    </Form.Item>
                    <Form.Item
                        label="Remarks"
                        name="remarks"
                    >
                        <Input placeholder="Type here..." />
                    </Form.Item>
                </Form>
                <div>
                    <div>
                        <Button onClick={e => this.form.current?.submit()}>{this.state.showEdit ? "Update" : "Add Purchase"}</Button>
                        {this.state.showEdit ? <Button style={{marginLeft: "10px"}} onClick={e => {
                                typeof this.props.handleFunction == "function" && this.props.handleFunction("reset");
                                this.form.current?.resetFields();
                            }}>Reset</Button> : null}
                    </div>
                    <div className="form-summary-container">
                        <div className="amount-container total">
                            <div>Total: ₹</div>
                            <div>{this.state.total || ""}</div>
                        </div>
                        <div className="amount-container gst">
                            <div>{`GST (${this.state.gst || 0}%) : ₹ `}</div>
                            <div>{this.state.gst_amount || ""}</div>
                        </div>
                        <div className="amount-container total-amount">
                            <div>Total Amount: ₹ </div>
                            <div>{this.state.total_amount || ""}</div>
                        </div>
                        <div className="amount-container paid">
                            <div>Amount Paid: ₹ </div>
                            <div>{this.state.amount_paid || ""}</div>
                        </div>
                        <div className="amount-container pending">
                            <div>Pending Amount: ₹ </div>
                            <div>{this.state.pending_amount || ""}</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}