export default {
  validateEmail: ({ getFieldValue }) => ({
    validator(_, value) {
      if (value) {
        let isValid = String(value)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        if (isValid) {
          return Promise.resolve();
        } else {
          return Promise.reject(new Error('Please enter valid E-mail'));
        }
      }
      return Promise.reject(new Error());
    },
  }),
  validatePassword: ({ getFieldValue, validateFields }) => ({
    validator(_, value) {
      if (value) {
        console.log(getFieldValue('confirmPassword'));
        if (value.length < 8) {
          return Promise.reject(
            new Error('Password must be minimum of 8 characters!')
          );
        }
        return Promise.resolve();
      }
      return Promise.reject(new Error());
    },
  }),
  validateConfirmPassword: ({ getFieldValue }) => ({
    validator(_, value) {
      if (value) {
        if (value.length < 8) {
          return Promise.reject(
            new Error('Confirm password must be minimum of 8 characters!')
          );
        } else if (
          getFieldValue('password') &&
          value != getFieldValue('password')
        ) {
          return Promise.reject(
            new Error('Confirm password should match with password!')
          );
        }
        return Promise.resolve();
      }
      return Promise.reject(new Error());
    },
  }),
  ValidateOtp: (value) => {
    console.log(value)
    if (value) {
      if (value.length < 4 || isNaN(value)) {
        return 'Please enter valid OTP';
      } else {
        return null;
      }
    } else {
      return 'OTP is required';
    }
  },
};
