import React from "react";
import { PDFDownloadLink, Document, Page, PDFViewer, StyleSheet, View, Text, Image } from '@react-pdf/renderer';

import Header from "./Header";
import CustomerDetails from "./CustomerDetails";
import ProductDetails from "./ProductDetails";
import ReceiverDetails from "./ReceiverDetails";
import FooterComponent from "./FooterComponent";

const styles = StyleSheet.create({
    page: {
        "font-family": "sans-serif",
        fontSize: 8,
        padding: "25px 15px 20px 15px",
        lineHeight: 1.5,
        flexDirection: 'column'
    },
    cancelTextCss: {
        position: "fixed",
        zIndex: 9999,
        fontSize: "75px",
        fontWeight: 900,
        letterSpacing: "14px",
        color: "#e14c4c"
    },
    cancelContainer: {
        height: "0px", 
        position: "absolute",
        top: "45%",
        left: "10%",
        opacity: 0.4,
        transform: "rotate(-45deg)"
    }
});


const PdfDownLoader = (props) => (
    <Document style={{ width: "100%"}}>
        <Page size="A4" style={styles.page}>
            <View style={{ border: "1px solid #000", padding: "5px 0px" }}>
            
                <Header />
                <CustomerDetails {...props}/>
                <View break={true}>
                    <ProductDetails {...props}/>
                </View>
                {/* <ReceiverDetails {...props}/> */}
                <FooterComponent {...props}/>
                {props.pdfInfo && props.pdfInfo.status && props.pdfInfo.status.indexOf("Cancelled") > -1 ? <View style={styles.cancelContainer}>
                     <Text style={{...styles.cancelTextCss}}>{props.pdfInfo.status}</Text>
                </View> : null}
               
            </View>
        </Page>
    </Document>
);

export default PdfDownLoader;