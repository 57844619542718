import axios from "axios";
import ACTIONS from "../redux/actions";
import moment from "moment";

// const hostName = "https://us-central1-peakpackersnode.cloudfunctions.net/App/"
// const hostName = "https://peakpackersnode.el.r.appspot.com/"
const hostName = "http://localhost:8000/"
// const hostName = "https://peakpackersnode.herokuapp.com/"
class Service {

    LOGIN(username, password) {
        if (username && password) {
            return axios.post(`${hostName}login`, {
                username,
                password
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(res => {
                return res.data;
            }).catch(err => {
                console.log(err)
            })
        }
    }

    GET(request, filter, data) {
        if (request && typeof request == "string") {
            return axios.post(`${hostName}search`, {
                queryName: request,
                filters: Array.isArray(filter) ? filter : (typeof filter == "object" ? [filter] : [])
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(res => {
                return Array.isArray(res.data) && res.data.length ? res.data : [];
            }).catch(err => {
                console.log(err)
            })
        }
    }

    UPDATE(request, data, sendArray) {
        if (request && typeof request == "string") {
            return axios.post(`${hostName}update`, {
                queryName: request,
                data: !Array.isArray(data) && typeof data == "object" ? data : (Array.isArray(data) && data.length ? (sendArray ? data :  data[0]) : {})
            }).then(res => {
                if (res.data.exist) {
                    alert("Already Exist")
                }
                return res.data
            }).catch(err => {
                console.log(err, "test")
            })
        }
    }

    DELETE(request, filters) {
        if (request && typeof request == "string") {
            return axios.post(`${hostName}delete`, {
                queryName: request,
                filters: Array.isArray(filters) ? filters : (typeof filters == "object" ? filters : [])
            }).then(data => {
                return data
            }).catch(err => {
                console.log(err)
            })
        }
    }

    DISTINCT({indexName, groupby, projection = [], filters}) {
        if (indexName && typeof indexName == "string") {
            return axios.post(`${hostName}${"distinct"}`, {
                indexName: indexName,
                groupby: Array.isArray(groupby) ? groupby[0] : (typeof groupby == "object" ? groupby : {}),
                projection,
                filters
            }).then(res => {
                return res.data
            }).catch(err => {
                console.log(err)
            })
        }
    }

    SEND_FILE(request, blob, fileName) {
        // let formData = new FormData();
        // formData.append("file", blob);
        if (request && typeof request == "string") {
            return axios.post(`${hostName}share`, {
                file: blob,
                queryName: request
            }).then(data => {
                return data
            }).catch(err => {
                console.log(err)
            })
        }
    }

    fetchCustomerData(data) {
        return ((dispatch, getState) => {
            return this.GET("get_customer_data", [], {}).then(res => {
                dispatch(ACTIONS.setCustomerTableData(res && res.length ? res : res));
            }).catch(err => {
                console.log(err)
            });
        })
    }

    fetchProductData(data) {
        return ((dispatch, getState) => {
            return this.GET("get_product_data", [], {}).then(res => {
                dispatch(ACTIONS.setProductTableData(res && res.length ? res : res));
            }).catch(err => {
                console.log(err)
            });
        })
    }

    verifyUserDetails(token) {
        if (token) {
            return axios.post(`${hostName}${"user"}`, {
                userkey: token
            }, {
                headers: {
                    "Access-Control-Allow-Origin": "*"
                }
            }).then(res => {
                return res.data;
            }).catch(err => {
                return err;
            })
        }
    }

    sortByKey(data, key, order) {
        let orderValue = order == "desc" ? -1 : 1;
        let sortedData = data.sort((a, b) => {
            if (a[key] > b[key])
                return orderValue;
            if (a[key] < b[key])
                return orderValue * -1;
            return 0;
        });
        return sortedData;
    }

    multiLevelSort = (data, sortBy) => {
        if (data.length) {
            let sortedData = data.sort(function (a, b) {
                let i = 0, result = 0, direction = 0;
                while (i < sortBy.length && result === 0) {
                    direction = sortBy[i].order == 'asc' ? 1 : (sortBy[i].order == 'desc' ? -1 : 0);
                    result = direction * (a[sortBy[i].field] < b[sortBy[i].field] ? -1 : (a[sortBy[i].field] > b[sortBy[i].field] ? 1 : 0));
                    i++;
                }

                return result;
            });
            return sortedData;
        }

        return data;

    }

    Sort(data, orderBy, sortBy, isDate) {
        if (Array.isArray(data)) {
            let order = orderBy == "asc" ? -1 : 1
            return data.sort((a, b) => {
                let first = typeof a == "object" && typeof sortBy == "string" ? a[sortBy] : a,
                    second = typeof b == "object" && typeof sortBy == "string" ? b[sortBy] : b;
                if (isDate) {
                    if (moment(first).isAfter(second)) return 1 * order;
                    if (moment(second).isAfter(first)) return -1 * order;
                }
                else {
                    if (first < second) return 1 * order;
                    if (first > second) return -1 * order;
                }
                return 0;
            });
        }

        return data;
    }

    titleCase(str) {
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }

    navigate(pathname) {
        var refresh = window.location.protocol + "//" + window.location.host + pathname;
        window.history.pushState({ path: refresh }, '', refresh);
    }
}

const service = new Service;
export default service;