import React from 'react';
import { Form, Input, Button } from 'antd';
import styles from './authentication.module.css';
import Validators from './Validators';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.form = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(formData) {
    this.props.setModule('changePassword', { from: 'forgotPassword' });
  }

  componentDidMount() {}

  render() {
    return (
      <div className={styles['container']} style={this.props.style}>
        <div className={styles['container-body']}>
          <h3 className={styles['authentication-title']}>
            Forgot Your Password
          </h3>
          <Form
            name="forgotPassword"
            ref={(e) => {
              this.form = e;
            }}
            initialValues={{ remember: true }}
            onFinish={this.handleSubmit}
            autoComplete="off"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Username is required',
                },
                Validators.validateEmail,
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Send OTP
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
