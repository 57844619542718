import { TimePicker, DatePicker, Select } from 'antd';
import {SwapRightOutlined} from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import 'antd/dist/antd.css';

const { Option } = Select;

const popupStyle = {
    zIndex: 999999
}
export default class DateTimePicker extends React.Component {
    _baseClassName = "date-time-picker-container";
    constructor(props) {
        super(props);

        this.state = {
            selectedValue: [],
            customPickerEndOpen: false,
            customPickerMoment: []
        }

        this.handleChange = this.handleChange.bind(this);
    }

    getDefaultValue(value) {
        if(value) {
            let splittedValue = value.split(":");
            let modifiedHrs = Number(splittedValue[0]), lastIndexValue = splittedValue[splittedValue.length -1];
            if(isNaN(lastIndexValue.trim())) {
                if(lastIndexValue.toLowerCase().includes("pm") && modifiedHrs < 12) {
                    lastIndexValue = lastIndexValue.split(" ")[0];
                    modifiedHrs = modifiedHrs + 12
                } else if (lastIndexValue.toLowerCase().includes("am")) {
                    lastIndexValue = lastIndexValue.split(" ")[0]
                }
            }
            
            if(splittedValue.length < 2) {
                return null;
            } else if(splittedValue.length == 2) {
                return moment(`${modifiedHrs}:${Number(lastIndexValue)}`, 'hh:mm')
            } else if(splittedValue.length == 3) {
                return moment(`${modifiedHrs}:${Number(splittedValue[1])}:${Number(lastIndexValue)}`, 'hh:mm:ss')
            }
        } else {
            return value;
        }

    }

    getCustomTimeSlots() {
        let tempArray = [], stepBy = this.props.minuteStep || 0;
        for(let i=0; i < 12; i++) {
                if(stepBy) {
                    for(let j=0; j<= 60; j++) {
                        if(j%stepBy == 0 && j != 60) {
                            tempArray.push(`${i > 9 ? i : ("0" + i)}:${j>9 ? j : ('0'+ j)}`)
                        }
                    }
                } else {
                    tempArray.push(`${i > 9 ? i : ("0" + i)}:00`)
                }
            
        }
        return tempArray;
    }

    generateCustomTimePickers(props) {
        let { placeholder, label, type } = props || {};
        let slotsArray = this.getCustomTimeSlots() || [];
        let AM_array = slotsArray.map(m => `${m} AM`);
        let PM_array = slotsArray.map((m, i) => {
            if (i == 0) {
                let tempVar = m.split(":")[1];
                m = `12:${tempVar}`
            }
            return `${m} PM`
        });
        let generatedTimeSlots = [...AM_array, ...PM_array];
        const style = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"
        }
        return (
            <div style={style}>
                {label && <div>{label}</div>}
                <Select style={{ width: "125px" }}
                    showSearch
                    placeholder={placeholder || "Search to Select"}
                    optionFilterProp="children"
                    open={props.open}
                    onDropdownVisibleChange={props.onDropdownVisibleChange}
                    filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    onSelect={(value) => props.onChange && typeof props.onChange == "function" && props.onChange(value) || ( this.props.type == "customTimePicker" && this.handleChange(null, value))}
                >
                    {(generatedTimeSlots || []).map((m, i) => {
                        let disabled = false;
                        if(this.props.type == "CustomTimeRangePicker" && type == "end") {
                            let index = generatedTimeSlots.findIndex(n => n == this.state.selectedValue[0])
                            if(i <= index) {
                                disabled = true
                            }
                        }
                        return <Option disabled={disabled} key={m} value={m}>{m}</Option>
                    })}
                </Select>
            </div>
        )
    }

    generateCustomTimeRangePickers(props) {
        const style = {
            display: "flex",
            alignItems: "center"
        }
        return (
            <div className={this._baseClassName + "-custom-time-range-pickers"} style={style}>
                {this.generateCustomTimePickers({
                    type: "start",
                    placeholder: props.placeholder && props.placeholder[0],
                    label: props.label && props.label[0],
                    onChange: (value) => this.handleCustomPickerChange(value, "start"),
                    open: this.state.startOpen,
                    onDropdownVisibleChange: (open) => {
                        if(!open) {
                            this.setState({ endOpen: true });
                        }
                        this.setState({ startOpen: open });
                    }
                })}
                <SwapRightOutlined className='custom-range-pickers-separator' style={{margin: "15px 20px 0px 20px", fontSize: "20px", color: "#ccc"}}/>
                {this.generateCustomTimePickers({
                    type: "end",
                    placeholder: props.placeholder && props.placeholder[1],
                    label: props.label && props.label[1],
                    onChange: (value) => this.handleCustomPickerChange(value, "end"),
                    open: this.state.endOpen,
                    onDropdownVisibleChange: (open) => {
                        this.setState({ endOpen: open });
                    }
                })}
            </div>
        )
    }

    generateSeparateRangePickers (props) {
        const { defaultValue, placeholder, label } = props;
        let generatedLabel = this.generateLabel(label), generatedPlaceHolder = this.generatePlaceHolder(placeholder);
        const labelStyle = {
            display: "flex"
        }
        return (
            <div className={this._baseClassName + "-custom-picker"} style={{display: "flex", alignItems: "center"}}>
                <div className={this._baseClassName + "-start"}>
                    {label && generatedLabel ? <div style={labelStyle} className='label'>{generatedLabel[0]}</div> : null}
                    <TimePicker {...props} 
                    onChange={(moment, value) => this.handleCustomPickerChange(value, "start", moment)}
                    defaultValue={defaultValue[0]} 
                    placeholder={generatedPlaceHolder[0]} 
                    onOpenChange={this.handleCustomStartOpenChange} />
                </div>
                <SwapRightOutlined className='custom-range-pickers-separator' style={{margin: "15px 20px 0px 20px", fontSize: "20px", color: "#ccc"}}/>
                <div className={this._baseClassName + "-end"}>
                    {label && generatedLabel ? <div style={labelStyle} className="label">{generatedLabel[1]}</div> : null}
                    <TimePicker {...props} 
                    onChange={(moment, value) => this.handleCustomPickerChange(value, "end", moment)}
                    defaultValue={defaultValue[1]} 
                    placeholder={generatedPlaceHolder[1]} 
                    open={this.state.customPickerEndOpen}
                    onOpenChange={this.handleCustomEndOpenChange} />
                </div>
            </div>
        );
    }

    generateLabelAndPlaceholder(label) {
        let type = this.props.type;
        if(label) {
            if((type == "TimeRange" || type == "DateRange" || type == "CustomTimeRangePicker") && this.props.separateRangePickers && Array.isArray(label)) 
                return label;
            return [label];
        } else {
            if((type == "TimeRange" || type == "DateRange" || type == "CustomTimeRangePicker") && this.props.separateRangePickers) 
                return ["From", "To"];
            return ["Select"];
        }
    }


    handleDropDownOpen(start, end) {
        if(!start) {
            this.setState({
                startOpen: true
            })
        } 
        if(!end) {
            this.setState({
                endOpen: true
            })
        }
    }
  handleCustomPickerChange = (value, type, moment) => {
    let tempValue = Array.isArray(this.state.selectedValue) ? this.state.selectedValue : [this.state.selectedValue];
    let tempMoment = this.state.customPickerMoment || [];
    if(type == "start") {
        tempValue[0] = Array.isArray(value) ? value[0] : value;
        tempMoment[0] = moment;
        this.handleDropDownOpen(this.state.selectedValue[0], this.state.selectedValue[1]);
    } else if(type == "end") {
        tempValue[1] = Array.isArray(value) ? value[0] : value;
        tempMoment[1] = moment;
        this.handleDropDownOpen(this.state.selectedValue[0], this.state.selectedValue[1]);
    }
        
    if(this.state.selectedValue[0] && this.state.selectedValue[1]) {
        this.handleChange(tempMoment, tempValue);
    }
        
  }

  handleChange(momentObj, value) {
      if(this.props.onChange && typeof this.props.onChange == "function") {
          this.props.onChange(value, momentObj);
      } 
      this.setState({
          selectedValue: Array.isArray(value) && value || [value]
      })
  }

  handleCustomStartOpenChange = (open) => {
    if (!open) {
      this.setState({ customPickerEndOpen: true });
    } else{
      setTimeout(()=>setTimeout(() => {
        var inputs = document.getElementsByClassName("ant-calendar-input");
        if (inputs.length > 0 && inputs[0]) {
          inputs[0].blur();
        }
      }));
    }
  }
  

  handleCustomPickerEndOpenChange(open) {
    this.setState({ customPickerEndOpen: open });
  }
    renderTimerPicker() {
        let type = this.props.type
        let timePickerProps = {
            size: this.props.size || "medium",
            use12Hours: true,
            defaultValue: this.getDefaultValue(this.props.defaultValue) || null,
            placeholder: this.generateLabelAndPlaceholder(this.props.placeholder),
            label: this.generateLabelAndPlaceholder(this.props.label),
            allowClear: this.props.allowClear || true,
            clearIcon: this.props.clearIcon,
            clearText: this.props.clearText || "Clear",
            disabled: this.props.disabled || false,
            showNow: this.props.showNow == undefined ? true : (this.props.showNow == false ? this.props.showNow : true ),
            popupStyle: popupStyle,
            popupClassName:  (this._baseClassName + "-" + (this.props.popupClassName || "time-picker")),
            onChange: this.handleChange

        }
        if(type == "Time") {
            return <TimePicker {...timePickerProps}  className={this._baseClassName + '-' + this.props.className} />
        } else if (type == "TimeRange") {
            if(this.props.separateRangePickers) {
                return this.generateSeparateRangePickers(timePickerProps)
            }
            return <TimePicker.RangePicker {...timePickerProps} disabledTime={this.handleDisabledTime} className={this._baseClassName + '-' + this.props.className}/>
        } else if(type == "CustomTimePicker") {
            return this.generateCustomTimePickers(timePickerProps)
        } else if (type == "CustomTimeRangePicker") {
            return this.generateCustomTimeRangePickers(timePickerProps)
        } else if (type == "Month") {
            return <DatePicker {...timePickerProps}  className={this._baseClassName + '-' + this.props.className} picker="month" />
        } else if (type == "Year") {
            return <DatePicker {...timePickerProps}  className={this._baseClassName + '-' + this.props.className} picker="year" />
        } else if(type == "Date") {
            return <DatePicker {...timePickerProps}  className={this._baseClassName + '-' + this.props.className} />
        } else {
            return <DatePicker {...timePickerProps}  className={this._baseClassName + '-' + this.props.className} />
        }  
    }

    render() {
        return (
            <>
                <div className={this._baseClassName}>
                    { this.renderTimerPicker() }
                </div>
            </>
        )
    }
}