import React from 'react';
import { Modal } from 'antd';
import Logo from './Logo';

const WrapperComponent = (
  Component,
  getRenderContent,
  customProps = {},
  closePopup
) => {
  return class extends React.Component {
    _baseClassName = 'wrapper-component';
    constructor(props) {
      super(props);
      this.state = {
        isModalVisible: Component ? true : false,
      };

      this.handleClose = this.handleClose.bind(this);
    }

    handleClose() {
      this.setState({
        isModalVisible: false,
      });
    }

    render() {
      return (
        <div className={this._baseClassName}>
          {/* <Modal
            title="Basic Modal"
            maskClosable={false}
            centeredsa
            visible={this.state.isModalVisible}
            footer={null}
            onCancel={this.handleClose}
            bodyStyle={{ padding: 'unset', display: 'flex' }}
          > */}
            {/* <Logo style={{ flex: 1 }} /> */}
            <Component
              style={{ flex: 1 }}
              setModule={getRenderContent}
              {...customProps}
            />
          {/* </Modal> */}
        </div>
      );
    }
  };
};

export default WrapperComponent;
