import React from "react";
import { Document, Page, PDFViewer, StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import service from "../../common/service";

const styles = StyleSheet.create({
    customer_container: {
        borderTop: "1px solid #000",
        display: "flex",
        flexDirection: "row",
        marginTop: "10px"
    },
    left_portion: {
        flex: 2,
        borderRight: "1px solid #000"
    },
    right_portion: {
        flex: 1
    },
    name: {
        "display": "flex",
        "flexDirection": "row"
    },
    name_label: {
        flex: 2,
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px 0px",
        height: "100%",
        fontWeight: 600,
        borderRight: "1px solid #000"
    },
    name_value: {
        flex: 8,
        textAlign: "left",
        padding: "3px 5px",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    phone: {
        "display": "flex",
        "flexDirection": "row"
    },
    phone_label: {
        flex: 3,
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px 0px",
        height: "100%",
        fontWeight: 600,
        borderRight: "1px solid #000"
    },
    phone_value: {
        flex: 8,
        textAlign: "left",
        padding: "3px 5px",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    p_order_label: {
        flex: 2,
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px 0px",
        height: "100%",
        fontWeight: 600,
        borderRight: "1px solid #000"
    },
    p_order_value: {
        flex: 9,
        textAlign: "left",
        padding: "3px 5px",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
    right: {
        "display": "flex",
        "flexDirection": "row",
        borderBottom: "1px solid #000"
    },
    right_label: {
        flex: 2,
        textAlign: "left",
        justifyContent: "center",
        alignItems: "center",
        padding: "3px 5px",
        height: "100%",
        fontWeight: 600,
        borderRight: "1px solid #000"
    },
    right_value: {
        flex: 3,
        textAlign: "left",
        padding: "3px 5px",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
    },
});

const CustomerDetails = (props) => {
    let { customer_details, invoice_date, invoice_number, receiver_details, annual_year, formatted_invoice_number } = props.pdfInfo;
    let current_date = invoice_date
    // if(typeof invoice_date != "string") {
        var date = new Date(invoice_date);
        let dd = String(date.getDate()).padStart(2, '0');
        let mm = String(date.getMonth() + 1).padStart(2, '0');
        let yyyy = date.getFullYear();
        current_date = `${dd}/${mm}/${yyyy}`;
    // }
    
    customer_details.address = `${customer_details.customer_address}, Contact: ${customer_details.customer_phone}`
    return (
        <View style={{ display: "flex", "flexDirection": "column" }}>
            <View style={styles.customer_container}>
                <View style={styles.left_portion}>
                    <View style={{ borderBottom: "1px solid #000" }}>
                        <View style={styles.name}>
                            <Text style={styles.name_label}>M/s</Text>
                            <Text style={styles.name_value}>{customer_details.customer_name}</Text>
                        </View>
                    </View>
                    <View style={{ borderBottom: "1px solid #000", height: "38px", padding: '3px 5px' }}>
                        <Text style={styles.address}>{customer_details.address}</Text>
                    </View>
                    <View style={{ borderBottom: "1px solid #000" }}>
                        <View style={styles.phone}>
                            <Text style={styles.phone_label}>Place of Supply</Text>
                            <Text style={styles.phone_value}>{service.titleCase(customer_details.place_of_supply)}</Text>
                        </View>
                    </View>
                    <View style={{ borderBottom: "1px solid #000" }}>
                        <View style={styles.phone}>
                            <Text style={styles.phone_label}>P. Order No </Text>
                            <Text style={styles.phone_value}>{customer_details.customer_order_number}</Text>
                        </View>
                    </View>
                    
                    {/* <View style={{ borderBottom: "1px solid #000" }}>
                    <View style={styles.phone}>
                        <Text style={styles.phone_label}>P. Order No </Text>
                        <Text style={styles.phone_value}>{customerDetails.p_order_number}</Text>
                    </View>
                </View> */}
                </View>
                <View style={styles.right_portion}>
                    <View style={styles.right}>
                        <Text style={styles.right_label}>Invoice No</Text>
                        {annual_year ? <Text style={styles.right_value}>{formatted_invoice_number}</Text> : <Text style={styles.right_value}>{invoice_number}</Text>}
                    </View>
                    <View style={styles.right}>
                        <Text style={styles.right_label}>Invoice Date</Text>
                        <Text style={styles.right_value}>{current_date}</Text>
                    </View>
                    {/* <View style={styles.right}>
                    <Text style={styles.right_label}>P. Order No </Text>
                    <Text style={styles.right_value}>{customerDetails.p_order_number}</Text>
                </View> */}
                    <View style={styles.right}>
                        <Text style={styles.right_label}>Party’s GST No</Text>
                        <Text style={styles.right_value}>{customer_details.customer_gst_number}</Text>
                    </View>
                    <View style={styles.right}>
                        <Text style={styles.right_label}>State Code</Text>
                        <Text style={styles.right_value}>{customer_details.state_code}</Text>
                    </View>
                    <View style={styles.right}>
                        <Text style={styles.right_label}>E-Way No </Text>
                        <Text style={styles.right_value}>{receiver_details.eway_number}</Text>
                    </View>
                </View>


            </View>
            {/* <View style={{ borderBottom: "1px solid #000" }}>
                <View style={styles.phone}>
                    <Text style={styles.phone_label}>P. Order No </Text>
                    <Text style={styles.phone_value}>{customer_details.customer_order_number}</Text>
                </View>
                <View style={styles.right}>
                    <Text style={styles.right_label}>E-Way No </Text>
                    <Text style={styles.right_value}>{receiver_details.eway_number}</Text>
                </View>
            </View> */}
        </View>
    )
};

export default CustomerDetails;