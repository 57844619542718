import React from 'react';
import OtpInput from 'react-otp-input';
import Timer from './Timer';
import { Space, Button } from 'antd';
import styles from './authentication.module.css';
import Validators from './Validators';

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      otp: '',
      errorMsg: null,
    };

    this.handleOTPChange = this.handleOTPChange.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
  }

  handleOTPChange(value) {
    this.setState({
      otp: value,
      errorMsg: null,
    });
  }

  handleVerify() {
    this.setState({
      errorMsg: Validators.ValidateOtp(this.state.otp),
    });
  }

  render() {
    return (
      <div
        className={this.props.className || ' ' + styles['container']}
        style={this.props.style || {}}
      >
        <div className={styles['container-body']}>
          <h3 className={styles['.authentication-title']}>
            {this.props.title || 'Create an Account'}
          </h3>
          <span className={styles['otp-label']}>
            Enter the One Time Password sent to your email {this.props.email}
          </span>
          <Space direction="vertical" size={[8, 16]}>
            <OtpInput
              value={this.state.otp}
              onChange={this.handleOTPChange}
              numInputs={4}
              inputStyle={{
                margin: '0px 10px',
                width: '28px',
                height: '45px',
                border: '1px solid #ccc',
                borderRadius: '2px',
              }}
            />
            {this.state.errorMsg ? (
              <div className={styles['error-message']}>
                {this.state.errorMsg}
              </div>
            ) : null}
            <Timer
              prefix="One Time Password sent to the registered mail was expired in "
              resendContent="Didn't recieve OTP?"
            />
          </Space>
          <Button onClick={this.handleVerify}>Verify</Button>
        </div>
      </div>
    );
  }
}
