
export default {
    MenuContainer: {
        position: "fixed",
        top: "50px",
        "zIndex": "99"
    },
    MenuContent: {
        width: "max-content",
        background: "#2f4f4f",
    },
    MenuHeader: {
        width: "100%",
        display: "flex",
        padding: "10px"
    },
    MenuLogo: {
        width: "30px"
    }, 
    MenuHeaderTitle: {
        color: "#fff",
        opacity: 0.7,
        padding: "0px 10px",
        fontSize: "18px"
    },
    MenuList: {
        margin: "unset",
        background: "#2f4f4f",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    ConvertToUpperCase: {
        "textTransform":"uppercase"
    },
    ConvertToCapitalCase: {
        "textTransform":"capitalize"
    },
    TableIconsContainer: {
        display: "flex",
        "justifyContent": "space-around"
    },
    TableIcons: {
        width: "35px",
        height: "25px",
        background: "#e44d26",
        border: "unset",
        color: "#fff",
    },
    CustomerSearch: {
        display: "flex",
        justifyContent: "space-between",
        padding: "0px 24px"
    },
    AddCustomerButton: {
        background: "#2f4f4f",
        color: "#fff",
        width: "auto",
        height: "35px"
    },
    PopUpAddButton: {
        display: "flex",
        "justifyContent": "center",
        width: "auto",
        margin: "auto"
    },
    printLoader: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
        background: "rgb(251 248 248 / 60%)",
        zIndex: 999999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    pdfControls: {
        zIndex: 99999,
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        fontSize: "20px",
        height: "60px",
        justifyContent: "space-between",
    },
    pdfControlsIcons: {
        border: "1px solid grey",
        borderRadius: "25px"
    },
    button: {
        width: "200px",
        backgroundColor: "#ffd666",
        color: "#000",
        padding: "10px 0px",
        height: "100%",
        // margin: "auto",
        display: "flex",
        justifyContent: "center",
    }
}


