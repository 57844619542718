import { Form, Select, Button, Input, Row, Col, Checkbox, Divider, DatePicker, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import style from "../css/common.js";
import state_list from "../data/state_list.json";
import service from "../common/service";
import { isMobile } from "react-device-detect";
import moment from "moment";
import AntdTableComponent from './AntdTableComponent';

const spanValues = isMobile ? 24 : 12;
export const renderInvoiceNumber = (state, setState) => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();
    let current_date = `${dd}/${mm}/${yyyy}`;
    return (
        <div>
            <Row>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 :6 }}
                        wrapperCol={{ span: isMobile ? 24 :10 }}
                        name="invoice_number" labelAlign="left" label="Invoice No"
                        rules={[{ required: true, message: '' }]}>
                        <Input type="text" onChange={e => {
                            const {invoice_number_list = []} = state;
                            let annual_year = `${moment().year()}-${moment().year() + 1}`
                            let isExists = invoice_number_list.find(m => m.invoice_number == e.target.value && m.annual_year == annual_year);
                            setState({
                                ...state,
                                isInvoiceNumberExist: !isExists ? false : true
                            });
                        }}/>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}
                        name="invoice_date" labelAlign="left" label="Date"
                        rules={[{ required: true, message: '' }]}>
                        <DatePicker
                            format='DD/MM/YYYY'
                            dateRender={current => {
                                const style = {};
                                if (current.date() === 1) {
                                    style.border = '1px solid #1890ff';
                                    style.borderRadius = '50%';
                                }
                                return (
                                    <div className="ant-picker-cell-inner" style={style}>
                                        {current.date()}
                                    </div>
                                );
                            }}
                            style={{width: "100%"}}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export const renderCustomerDetails = (form, state, setState) => {
    const customers = state.customerList.map(m => <Select.Option key={m._id} value={m._id}>{`${m.customer_name}`}</Select.Option>)
    return (
        <div>
            <Row>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                        name="customer_id" labelAlign="left" label="Customer Name"
                        rules={[{ required: true, message: 'Please input Customer Name!' }]}>
                        <Select showSearch placeholder="Select Customer Name"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={value => {
                                let obj = {};
                                let filters = [{ column: "customer_id", value: [value] }]
                                service.GET("get_product_data", filters, {}).then(res => {
                                    let productList = JSON.parse(JSON.stringify(res && res.length ? res : []))
                                    setState(() => ({
                                        ...state,
                                        productList: productList
                                    }))
                                }).catch(err => {
                                    console.log(err)
                                });
                                state.customerList.forEach(m => {
                                    if (value == m._id) {
                                        let state_name = state_list.reduce((acc, obj) => {
                                            if (obj.state_code == m.state_code) {
                                                acc = obj.state_name;
                                            }
                                            return acc;
                                        }, "")
                                        form.setFieldsValue({
                                            customer_email: m.customer_email,
                                            customer_phone: m.customer_phone,
                                            customer_gst_number: m.customer_gst_number,
                                            customer_msme_number: m.customer_msme_number,
                                            state_code: `${m.state_code} - ${state_name}`,
                                            customer_address: `${m.customer_address_line1}, ${m.customer_address_line2 ? m.customer_address_line2 + "," : ""} ${m.customer_address_line3 ? m.customer_address_line3 + "," : ""} ${m.city}, ${state_name} - ${m.zip_code}`,
                                        })
                                    }
                                });
                            }}
                        >
                            {customers}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="customer_order_number" labelAlign="left" label="P.Order Number"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}
                    >
                        <Input style={{ ...style.ConvertToUpperCase }} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={spanValues}>
                    <Form.Item name="customer_email" labelAlign="left" label="E-mail"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                    >
                        <Input disabled style={{ ...style.ConvertToUpperCase }} />
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="customer_phone" labelAlign="left" label="Phone"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}>
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={spanValues}>
                    <Form.Item name="customer_gst_number" labelAlign="left" label="Customer GST"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }} >
                        <Input style={{ ...style.ConvertToUpperCase }} disabled />
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="customer_msme_number" labelAlign="left" label="Customer MSME No"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}>
                        <Input style={{ ...style.ConvertToUpperCase }} disabled />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={spanValues}>
                    <Form.Item name="customer_address" labelAlign="left" label="Address"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }} >
                        <Input.TextArea autoSize disabled style={{ ...style.ConvertToUpperCase }} />
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="state_code" labelAlign="left" label="State"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}>
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}


export const renderProductDetails = (form, state, setState) => {
    const pSpanValues = 24;
    const productsConfig = {
        showTotalColumns: true,
        tableSize: "small",
        scroll: true,
        scrollY: 600,
        pageSize: 25,
        showIndex: false,
        indexTitle: "S.NO",
        showPagination: true,
        pageOptions: false,
        rowSelection: false,
        rowSelectionChange: function(keys, selectedRows) {
            console.log(keys, selectedRows)
        },
        enableRadioButton: false,
        selectedRowKeys: "selectedFilters",
        disableRowsSelection: [],
        showTableLegend: false,
        columns: [
            {
                title: "Product Category",
                dataIndex: "product_category",
                width: 70,
            },
            {
                title: "Product Dimension",
                dataIndex: "product_name",
                width: 180,
            },
            {
                title: "Price",
                dataIndex: "product_price",
                width: 50,
            },
            {
                title: "Quantity",
                dataIndex: "quantity",
                width: 50,
            },
            {
                title: "Actions",
                sort: false,
                width: 50,
                showFilter: false,
                fixed: 'right',
                render: (text, record, index) => {
                    // console.log(text, record, index)
                    return (
                        <div className="table-options" style={{display: "flex", justifyContent: "space-around"}}>
                            <Tooltip placement="top" title={"Edit"}>
                                <Button icon={<EditOutlined />} onClick={e => updateProductDetail(text, record, index)} />
                            </Tooltip>
                            <Tooltip placement="top" title={"Delete"}>
                                     <Button icon={<DeleteOutlined />} onClick={e => deleteProduct(text, record, index)} />

                            </Tooltip>
                        </div>
                    )
                }
            },
            
        ]
    }
    const productsCategory = (state.productList || []).reduce((acc, obj) => {
        if(!acc.includes(obj.product_category)) {
            acc.push(obj.product_category)
        }
        return acc;
    }, []).map(m => <Select.Option key={m} value={m}>{`${m}`}</Select.Option>);


    const updateProductDetail = (text, record, index) => {
        form.setFieldsValue(record);
    }

    const deleteProduct = (text, record, index) => {
        let filteredData = (state.selectedProductList).filter(m => m.product_id != record.product_id);
        setState({
            ...state,
            selectedProductList: filteredData
        });
    }

    const handleSubmit = (val) => {
        let values = state.productList.find(m => m._id == val.product_id);
        values = Object.assign(values, val)
        const { selectedProductList = [] } = state;
        let isExist = selectedProductList.find(m => m.product_id == values.product_id);
        let list = JSON.parse(JSON.stringify(selectedProductList));
        if (!isExist) {
            list.push(values);
        } else {
            list.forEach(m => {
                if (m.product_id == values.product_id) {
                    m = Object.assign(m, values)
                }
            });
        }

        list.forEach(m => {
            m.product_name = `${m.product_length} X ${m.product_width} X ${m.product_height}`;
        });

        setState({
            ...state,
            selectedProductList: list
        });
        form.resetFields();
    }

    return  (
        <div style={{display: "flex"}}>
            <Form
                form={form}
                scrollToFirstError
                onFinish={handleSubmit}
            >
                <Row>
                    <Col span={pSpanValues}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 18 }}
                            name={'product_category'}
                            labelAlign="left" label="Product Category"
                            rules={[{ required: true, message: 'Please input Product Category!' }]}>
                            <Select showSearch placeholder="Select Product Category"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={value => {
                                    let filteredProducts = state.productList.filter(m => {
                                        if (value == m.product_category) {
                                            return true
                                        }
                                    });
                                    // let selected = form.getFieldValue("products");
                                    // let obj = {
                                    //     product_id: null,
                                    //     product_description: null,
                                    //     product_category: value,
                                    //     product_hsn_code: null,
                                    //     product_price: null,
                                    //     quantity: null,
                                    // }
                                    // selected[name] = obj;
                                    // form.setFieldsValue({
                                    //     products: [...selected]
                                    // })
                                    setState(() => ({
                                        ...state,
                                        selectedProduct: filteredProducts.map(m => <Select.Option key={m._id} value={m._id}>{`${m.product_length} X ${m.product_width} X ${m.product_height}`}</Select.Option>)
                                    }));

                                }}
                            >
                                {productsCategory}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={pSpanValues}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 18 }}
                            name={'product_id'}
                            labelAlign="left" label="Product Dimension"
                            rules={[{ required: true, message: 'Please input Product Name!' }]}>
                            <Select showSearch placeholder="Select Product Name"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                onSelect={value => {
                                    let obj = {}, isExist = 0;
                                    let selected = state.productList || [];
                                    let selectedIds = (selected || []).filter(item => item._id == value);
                                    if (selectedIds.length > 1) {
                                        alert("Products Already Selected!!")
                                        obj["product_id"] = null;
                                        obj["product_description"] = null;
                                        obj["product_category"] = null;
                                        obj["product_hsn_code"] = null;
                                        obj["product_price"] = null;
                                        obj["quantity"] = null;
                                        obj["product_cft"] = null;
                                        obj["enableCFT"] = false;
                                        // selected[name] = obj;
                                        return 0
                                    } else {
                                        state.productList.forEach(m => {
                                            if (value == m._id) {
                                                obj["product_description"] = m.product_description;
                                                obj["product_category"] = m.product_category;
                                                obj["product_hsn_code"] = m.product_hsn_code;
                                                obj["product_price"] = m.product_price;
                                                obj["product_cft"] = m.product_cft;
                                                obj["quantity"] = 1;
                                                obj["product_id"] = m._id;
                                                obj["enableCFT"] = false;
                                            }
                                        });
                                    }
                                    form.setFieldsValue({
                                        ...obj
                                    })
                                }}
                            >
                                {state.selectedProduct}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={pSpanValues}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 18 }}
                            name={'product_description'}
                            labelAlign="left" label="Product Description"
                        >
                            <Input disabled style={{ ...style.ConvertToUpperCase }} />
                        </Form.Item>
                    </Col>
                    <Col span={pSpanValues}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 18 }}
                            name={'product_hsn_code'}
                            labelAlign="left" label="Product HSN CODE"
                        >
                            <Input disabled style={{ ...style.ConvertToUpperCase }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={pSpanValues}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 18 }}
                            name={'product_price'}
                            labelAlign="left" label="Price"
                            rules={[{ required: true, message: 'Please input price!' }]}
                        >
                            <Input prefix="&#x20b9;" type="number" />
                        </Form.Item>
                    </Col>
                    <Col span={pSpanValues}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 18 }}
                            name={'quantity'}
                            labelAlign="left" label="Quantity"
                            rules={[{ required: true, message: 'Please input quantity!' }]}
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={pSpanValues}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 18 }}
                        >
                            <Checkbox onChange={e => {
                                setState(() => ({
                                    ...state,
                                    enableCFT: e.target.checked
                                }));
                            }}>Click to Enable Cubic Feet</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                {
                    state.enableCFT ? <Row>
                        <Col span={pSpanValues}>
                            <Form.Item
                                labelCol={{ span: isMobile ? 24 : 6 }}
                                wrapperCol={{ span: isMobile ? 24 : 18 }}
                                name={'product_cft'}
                                labelAlign="left" label="Cubic Feet"
                            >
                                <Input type="number" style={{ ...style.ConvertToUpperCase }} />
                            </Form.Item>
                        </Col>
                    </Row> : null
                }
                <Button style={{ width: "unset", padding: "5px 24px" }} onClick={e => form.submit()} >{"Add Product"}</Button>
                <Divider />
            </Form>
            <AntdTableComponent style={{width: "60%", marginLeft: "20px", paddingRight: "10px"}} data={state.selectedProductList} config={productsConfig} />
        </div>
    )
    // return (
    //     <Form.List name="products" rules={[{ required: true, message: 'Please add atleast one product!' }]}>
    //         {(fields, { add, remove }) => {
    //             return (
    //                 <>
    //                     {fields.map(({ key, name, fieldKey, ...restField }) => {
    //                         return (
    //                             <div key={key}>
    //                                 <MinusCircleOutlined onClick={() => remove(name)} style={{ width: "20px", position: "relative", left: "95%" }} />
    //                                 <Row>
    //                                     <Col span={pSpanValues}>
    //                                         <Form.Item
    //                                             labelCol={{ span: isMobile ? 24 : 6 }}
    //                                             wrapperCol={{ span: isMobile ? 24 : 12 }}
    //                                             {...restField}
    //                                             name={[name, 'product_category']}
    //                                             fieldKey={[fieldKey, 'product_category']}
    //                                             labelAlign="left" label="Product Category"
    //                                             rules={[{ required: true, message: 'Please input Product Category!' }]}>
    //                                             <Select showSearch placeholder="Select Product Category"
    //                                                 optionFilterProp="children"
    //                                                 filterOption={(input, option) =>
    //                                                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //                                                 }
    //                                                 onSelect={value => {
    //                                                     let filteredProducts = state.productList.filter(m => {
    //                                                         if (value == m.product_category) {
    //                                                             return true
    //                                                         }
    //                                                     });
    //                                                     let selected = form.getFieldValue("products");
    //                                                     let obj = {
    //                                                         product_id: null,
    //                                                         product_description: null,
    //                                                         product_category: value,
    //                                                         product_hsn_code: null,
    //                                                         product_price: null,
    //                                                         quantity: null,
    //                                                     }
    //                                                     selected[name] = obj;
    //                                                     form.setFieldsValue({
    //                                                         products: [...selected]
    //                                                     })
    //                                                     setState(() => ({
    //                                                         ...state,
    //                                                         selectedProduct: filteredProducts.map(m => <Select.Option key={m._id} value={m._id}>{`${m.product_length} X ${m.product_width} X ${m.product_height}`}</Select.Option>)
    //                                                     }));

    //                                                 }}
    //                                             >
    //                                                 {productsCategory}
    //                                             </Select>
    //                                         </Form.Item>
    //                                     </Col>
    //                                     <Col span={pSpanValues}>
    //                                         <Form.Item
    //                                             labelCol={{ span: isMobile ? 24 : 6 }}
    //                                             wrapperCol={{ span: isMobile ? 24 : 12 }}
    //                                             {...restField}
    //                                             name={[name, 'product_id']}
    //                                             fieldKey={[fieldKey, 'product_id']}
    //                                             labelAlign="left" label="Product Dimension"
    //                                             rules={[{ required: true, message: 'Please input Product Name!' }]}>
    //                                             <Select showSearch placeholder="Select Product Name"
    //                                                 optionFilterProp="children"
    //                                                 filterOption={(input, option) =>
    //                                                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
    //                                                 }
    //                                                 onSelect={value => {

    //                                                     let obj = {}, isExist = 0;
    //                                                     let selected = form.getFieldValue("products");
    //                                                     let selectedIds = selected.filter(item => item.product_id == value);
    //                                                     if (selectedIds.length > 1) {
    //                                                         alert("Products Already Selected!!")
    //                                                         obj["product_id"] = null;
    //                                                         obj["product_description"] = null;
    //                                                         obj["product_category"] = null;
    //                                                         obj["product_hsn_code"] = null;
    //                                                         obj["product_price"] = null;
    //                                                         obj["quantity"] = null;
    //                                                         obj["product_cft"] = null;
    //                                                         obj["enableCFT"] = false;
    //                                                         selected[name] = obj;
    //                                                         return 0
    //                                                     } else {
    //                                                         state.productList.forEach(m => {
    //                                                             if (value == m._id) {
    //                                                                 obj["product_description"] = m.product_description;
    //                                                                 obj["product_category"] = m.product_category;
    //                                                                 obj["product_hsn_code"] = m.product_hsn_code;
    //                                                                 obj["product_price"] = m.product_price;
    //                                                                 obj["product_cft"] = m.product_cft;
    //                                                                 obj["quantity"] = 1;
    //                                                                 obj["product_id"] = m._id;
    //                                                                 obj["enableCFT"] = false;
    //                                                             }
    //                                                         });
    //                                                     }
    //                                                     let data = selected.map(m => {
    //                                                         if (m.product_id == value) {
    //                                                             m = Object.assign({}, obj)
    //                                                         }
    //                                                         return m
    //                                                     })
    //                                                     form.setFieldsValue({
    //                                                         products: [...data]
    //                                                     })
    //                                                 }}
    //                                             >
    //                                                 {state.selectedProduct}
    //                                             </Select>
    //                                         </Form.Item>
    //                                     </Col>
    //                                 </Row>
    //                                 <Row>
    //                                     <Col span={pSpanValues}>
    //                                         <Form.Item
    //                                             labelCol={{ span: isMobile ? 24 : 6 }}
    //                                             wrapperCol={{ span: isMobile ? 24 : 12 }}
    //                                             {...restField}
    //                                             name={[name, 'product_description']}
    //                                             fieldKey={[fieldKey, 'product_description']}
    //                                             labelAlign="left" label="Product Description"
    //                                         >
    //                                             <Input disabled style={{ ...style.ConvertToUpperCase }} />
    //                                         </Form.Item>
    //                                     </Col>
    //                                     <Col span={pSpanValues}>
    //                                         <Form.Item
    //                                             labelCol={{ span: isMobile ? 24 : 6 }}
    //                                             wrapperCol={{ span: isMobile ? 24 : 12 }}
    //                                             {...restField}
    //                                             name={[name, 'product_hsn_code']}
    //                                             fieldKey={[fieldKey, 'product_hsn_code']}
    //                                             labelAlign="left" label="Product HSN CODE"
    //                                         >
    //                                             <Input disabled style={{ ...style.ConvertToUpperCase }}/>
    //                                         </Form.Item>
    //                                     </Col>
    //                                 </Row>
    //                                 <Row>
    //                                     <Col span={pSpanValues}>
    //                                         <Form.Item
    //                                             labelCol={{ span: isMobile ? 24 : 6 }}
    //                                             wrapperCol={{ span: isMobile ? 24 : 12 }}
    //                                             {...restField}
    //                                             name={[name, 'product_price']}
    //                                             fieldKey={[fieldKey, 'product_price']}
    //                                             labelAlign="left" label="Price"
    //                                             rules={[{ required: true, message: 'Please input price!' }]}
    //                                         >
    //                                             <Input prefix="&#x20b9;"  type="number"/>
    //                                         </Form.Item>
    //                                     </Col>
    //                                     <Col span={pSpanValues}>
    //                                         <Form.Item
    //                                             labelCol={{ span: isMobile ? 24 : 6 }}
    //                                             wrapperCol={{ span: isMobile ? 24 : 12 }}
    //                                             {...restField}
    //                                             name={[name, 'quantity']}
    //                                             fieldKey={[fieldKey, 'quantity']}
    //                                             labelAlign="left" label="Quantity"
    //                                             rules={[{ required: true, message: 'Please input quantity!' }]}
    //                                         >
    //                                             <Input type="number" />
    //                                         </Form.Item>
    //                                     </Col>
    //                                 </Row>

    //                                 <Row>
    //                                     <Col span={pSpanValues}>
    //                                         <Form.Item
    //                                             labelCol={{ span: isMobile ? 24 : 6 }}
    //                                             wrapperCol={{ span: isMobile ? 24 : 12 }}
    //                                             {...restField}
    //                                         >
    //                                             <Checkbox onChange={e => {
    //                                                 setState(() => ({
    //                                                     ...state,
    //                                                     enableCFT: e.target.checked
    //                                                 }));
    //                                             }}>Click to Enable Cubic Feet</Checkbox>
    //                                         </Form.Item>
    //                                     </Col>
    //                                 </Row>
    //                                 {
    //                                     state.enableCFT ? <Row>
    //                                         <Col span={pSpanValues}>
    //                                             <Form.Item
    //                                                 labelCol={{ span: isMobile ? 24 : 6 }}
    //                                                 wrapperCol={{ span: isMobile ? 24 : 12 }}
    //                                                 {...restField}
    //                                                 name={[name, 'product_cft']}
    //                                                 fieldKey={[fieldKey, 'product_cft']}
    //                                                 labelAlign="left" label="Cubic Feet"
    //                                             >
    //                                                 <Input type="number" style={{ ...style.ConvertToUpperCase }}/>
    //                                             </Form.Item>
    //                                         </Col>
    //                                     </Row> : null
    //                                 }
    //                                 <Divider />
    //                             </div>
    //                         )
    //                     })}

    //                     <Row style={{ display: "flex", justifyContent: "center" }}>
    //                         <Col span={isMobile ? 24 : 4}>
    //                             <Form.Item>
    //                                 <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
    //                                     Add Product
    //                                 </Button>
    //                             </Form.Item>
    //                         </Col>
    //                     </Row>
    //                 </>
    //             )
    //         }}
    //     </Form.List>
    // )
}

export const renderGSTDetails = (form, state, setState) => {
    let selectedState = form.getFieldValue("state_code")?.split(" ")?.[0]
    return (
        <div>
            {selectedState == "33" || !selectedState ? <Row>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 12 }}
                        name="cgst"
                        labelAlign="left" label={"CGST"}
                        rules={[{ required: selectedState == "33" || !selectedState, message: 'Please input CGST!'}]}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 12 }}
                        name="sgst"
                        labelAlign="left" label={ "SGST"}
                        rules={[{ required: selectedState == "33" || !selectedState, message: 'Please input SGST!'}]}
                    >
                        <Input type="number"/>
                    </Form.Item>
                </Col>
            </Row> : <Row>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 12 }}
                        name="igst"
                        labelAlign="left" label={"IGST"}
                        rules={[{ required: !(selectedState == "33" || !selectedState), message: 'Please input IGST!'}]}
                    >
                        <Input type="number" />
                    </Form.Item>
                </Col>
            </Row>}
        </div>
    )
}

export const renderOtherDetails = (form, state, setState) => {
    const woodTypes = [
        { label: 'Pine Wood', value: 'pineWood' },
        { label: 'Ply Wood', value: 'plyWood' },
        { label: 'Rubber Wood', value: 'rubberWood' },
    ]
    return (
        <div>
            <Row>
                <Col span={24}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 3 }}
                        wrapperCol={{ span: isMobile ? 24 : 24 }}
                        name="material_details" labelAlign="left" label="Material Type"
                        rules={[{ required: true, message: 'Please input material type!' }]}>
                        <Checkbox.Group options={woodTypes} style={{width: isMobile ? "100%" : "50%", display: "flex", flexDirection: isMobile ? "column" : "row", padding: isMobile ? "0px 20px" : "unset"}}/>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                        name="place_of_supply" labelAlign="left" label="Place of Supply"
                        rules={[{ required: true, message: 'Please input place of supply!' }]}>
                        <Input style={{ ...style.ConvertToCapitalCase }}/>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                        name="eway_number" labelAlign="left" label="E-WAY Number"
                    >
                        <Input style={{ ...style.ConvertToCapitalCase }}/>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}

export const renderReceiverDetails = (form, state, setState) => {
    return (
        <div>
            <Row>
                <Col span={spanValues}>
                    <Form.Item name="receiver_name" labelAlign="left" label="Receiver Name"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                    >
                        <Input style={{ ...style.ConvertToUpperCase }}/>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="receiver_phone" labelAlign="left" label="Phone"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}
                    >
                        <Input style={{ ...style.ConvertToUpperCase }}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={spanValues}>
                    <Form.Item name="receiver_dl" labelAlign="left" label="Driving Licence"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 16 }}
                    >
                        <Input style={{ ...style.ConvertToUpperCase }}/>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item name="receiver_rc_number" labelAlign="left" label="Registration Number"
                        labelCol={{ span: isMobile ? 24 : 6 }}
                        wrapperCol={{ span: isMobile ? 24 : 10 }}
                    >
                        <Input style={{ ...style.ConvertToUpperCase }} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    )
}