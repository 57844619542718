import state_list from "../data/state_list.json";
import style from "../css/common.js"
import "../css/common.css"
import { connect } from "react-redux";
import ACTIONS from "../redux/actions";
import service from "../common/service";
import {
    Form,
    Input,
    Button,
    Select,
    message,
    Switch,
    Row,
    Col,
} from 'antd';
import { useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";

const spanValues = isMobile ? 24 : 12;

const AddOrUpdateCustomer = (props) => {

    const [customerForm] = Form.useForm();
    const state_options = state_list.map(m => <Select.Option key={m.state_code} value={m.state_code}>{`${m.state_short_name}-${m.state_name}`}</Select.Option>)
    const [updateValues, setUpdateValues] = useState( props.isUpdate ? props.updateCustomerValues : {});
    const [customersList, setCustomersList] = useState([]);


    useEffect(() => {
        if(props.isUpdate && props.updateCustomerFrom != "Menu") {
            setUpdateValues(props.updateCustomerValues)
        } else {
            restFormFields(customerForm);
        }
       
    }, [props.updateCustomerValues, props.isUpdate]);
    useEffect(() => {
        if(props.selectedMenu == "update_customer" && !props.updateCustomerValues.customer_name) {
            service.GET("get_customer_data", [], {}).then(res => {
                let customerList = JSON.parse(JSON.stringify(res && res.length ? res : []));
                setCustomersList(customerList);
            }).catch(err => {
                console.log(err)
            });
        }
    }, [props.selectedMenu]);

    const renderCustomerSelection = () => {
        const customers = customersList.map(m => <Select.Option key={m._id} value={m._id}>{`${m.customer_name}`}</Select.Option>);
        return (
            <Row>
                <Col span={spanValues}>
                    <Form.Item
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24}}
                        name="customer_id" labelAlign="left" label="Customer Name"
                        rules={[{ required: true, message: 'Please input Customer Name!' }]}>
                        <Select showSearch placeholder="Select Customer Name"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={value => {
                                customersList.forEach(m => {
                                    if (value == m._id) {
                                        let state_name = state_list.reduce((acc, obj) => {
                                            if (obj.state_code == m.state_code) {
                                                acc = obj.state_name;
                                            }
                                            return acc;
                                        }, "")
                                        customerForm.setFieldsValue({
                                            customer_email: m.customer_email,
                                            _id: m._id,
                                            customer_phone: m.customer_phone,
                                            customer_gst_number: m.customer_gst_number,
                                            customer_msme_number: m.customer_msme_number,
                                            state_code: `${m.state_code} - ${state_name}`,
                                            customer_address_line1: m.customer_address_line1,
                                            customer_address_line2: m.customer_address_line2,
                                            customer_address_line3: m.customer_address_line3,
                                            city: m.city,
                                            zip_code: m.zip_code,
                                            customer_address_line3: m.customer_address_line3,
                                        });
                                    }
                                });
                            }}
                        >
                            {customers}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        )
    }
    return (
        <div className="add-customer-container">
            {isMobile && props.isUpdate && <div style={{fontSize: "18px", fontWeight: 600, padding: "5px 0px 15px 0px"}}>Update Customer Details</div>}
            {isMobile && !props.isUpdate && <div style={{fontSize: "18px", fontWeight: 600, padding: "5px 0px 15px 0px"}}>Add Customer Details</div>}
            {isMobile && props.isUpdate && props.updateCustomerFrom == "Menu" ? renderCustomerSelection() : null}
            <div>
                <Form
                    name="customer"
                    form={customerForm}
                    labelCol={{ span: isMobile ? 24 : 8 }}
                    wrapperCol={{ span: isMobile ? 24 : 14 }}
                    layout="horizontal"
                    scrollToFirstError
                    onFinish={(data) => handleCustomerChange([data, props, customerForm])}
                    initialValues={{...updateValues}}
                    autoComplete="off"
                >
                    <Row>
                        {
                            props.isUpdate ? <Col span={spanValues}>
                                <Form.Item name="_id" labelAlign="left" label="Customer ID"
                                    rules={[{ required: true, message: 'Please input Customer ID!' }]}>
                                    <Input style={{ ...style.ConvertToUpperCase }} disabled={true}/>
                                </Form.Item>
                            </Col> : null
                        }
                        {isDesktop || ((!props.isUpdate || props.updateCustomerValues.customer_name) && isMobile) ? <Col span={spanValues}>
                            <Form.Item name="customer_name" labelAlign="left" label="Customer Name"
                                rules={[{ required: true, message: 'Please input Customer Name!' }]}>
                                <Input style={{ }} disabled={props.isUpdate}/>
                            </Form.Item>
                        </Col> : null}
                    </Row>
                    <Row>
                        <Col span={spanValues}>
                            <Form.Item name="customer_email" labelAlign="left" label="E-mail"
                                rules={[{ type: 'email', message: 'Please input valid E-mail!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={spanValues}>
                            <Form.Item name="customer_phone" labelAlign="left" label="Phone"
                                // rules={[
                                //     { type: 'string', required: true, message: 'Please input phone!' },
                                //     ({ getFieldValue }) => ({
                                //         validator(_, value) {
                                //             if (value.match(/^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/gm)) {
                                //                 return Promise.resolve();
                                //             }
                                //             return Promise.reject(new Error('Please input valid Phone!'));
                                //         },
                                //     })]}
                                    >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={spanValues}>
                            <Form.Item name="customer_gst_number" labelAlign="left" label="Customer GST"
                                rules={[{ required: true, message: 'Please input Customer GST!', type: "string", min: 6, max: 30 }]}>
                                <Input style={{ ...style.ConvertToUpperCase }} />
                            </Form.Item>
                        </Col>
                        <Col span={spanValues}>
                            <Form.Item name="customer_msme_number" labelAlign="left" label="Customer MSME No"
                                rules={[{  message: 'Please input valid Customer MSME No!', type: "string", min: 6, max: 30 }]}>
                                <Input style={{ ...style.ConvertToUpperCase }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={spanValues}>
                            <Form.Item name="customer_address_line1" labelAlign="left" label="Address Line 1"
                                rules={[{ required: true, message: 'Please input Address Line 1!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={spanValues}>
                            <Form.Item name="customer_address_line2" labelAlign="left" label="Address Line 2">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={spanValues}>
                            <Form.Item name="customer_address_line3" labelAlign="left" label="Address Line 3">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={spanValues}>
                            <Form.Item name="city" labelAlign="left" label="City"
                                rules={[{ required: true, message: 'Please input City!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={spanValues}>
                            <Form.Item name="state_code" labelAlign="left" label="State"
                                rules={[{ required: true, message: 'Please input State!' }]}>
                                <Select showSearch placeholder="Select State"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }>
                                    {state_options}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={spanValues}>
                            <Form.Item name="zip_code" labelAlign="left" label="Zipcode"
                                rules={[{ required: true, message: 'Please input valid Zipcode!', type: "string", min: 6, max: 7 }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={style.PopUpAddButton}>
                                <Button style={{...style.button, width: "100%"}} htmlType="submit" >{props.isUpdate ? "UPDATE" : "ADD CUSTOMER"}</Button>
                            </Form.Item>

                </Form>
            </div>
        </div>
    )
}

const restFormFields = (customerForm) => {
    customerForm.setFieldsValue({
        customer_email: undefined,
        customer_name: undefined,
        _id: undefined,
        customer_phone: undefined,
        customer_gst_number: undefined,
        customer_msme_number: undefined,
        state_code: undefined,
        customer_address_line1: undefined,
        customer_address_line2: undefined,
        customer_address_line3: undefined,
        city: undefined,
        zip_code: undefined,
    });
}

const handleCustomerChange = (values) => {
    let [data, props, customerForm] = values;
    let customerData = data || {};
    customerData.customer_name = customerData.customer_name;
    customerData.customer_msme_number = customerData.customer_msme_number ? customerData.customer_msme_number.toUpperCase(): null;
    customerData.customer_gst_number = customerData.customer_gst_number.toUpperCase();
    Object.keys(customerData).forEach(key => {
        if (!customerData[key]) {
            customerData[key] = ""
        }
    });
    if (props.isUpdate && typeof customerData == "object") {
            service.UPDATE("update_customer_data", [customerData]).then(res => {
                props.setCustomerTableData();
                restFormFields(customerForm);
                message.success('Updated Successfully');
            }).catch(err => err)
    } else {
            service.UPDATE("add_customer_data", [customerData]).then(res => {
                props.setCustomerTableData();
                restFormFields(customerForm);
                message.success('Added Successfully');
            }).catch(err => err)
    }
    props.closePopup && typeof props.closePopup && props.closePopup()

}

const mapStateToProps = state => {
    return {
        selectedMenu: state.selectedMenu,
        updateCustomerFrom: state.updateCustomerFrom,
        updateCustomerValues: state.updateCustomerValues
    }
};

const mapDispatchToProps = dispatch => ({
    updateCustomerDetails: data => dispatch(ACTIONS.updateCustomerDetails(data)),
    setCustomerTableData: data => dispatch(service.fetchCustomerData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddOrUpdateCustomer);