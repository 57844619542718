import state_list from "../data/state_list.json";
import style from "../css/common.js"
import "../css/common.css"
import { connect } from "react-redux";
import ACTIONS from "../redux/actions";
import service from "../common/service";
import {
    Form,
    Input,
    Button,
    Select,
    message,
    Typography,
    Row,
    Col,
} from 'antd';
import { useEffect, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import FileUploadComponent from "./FileUploadComponent";
import { UploadOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';

const spanValues = isMobile ? 24 : 12;
const { Text } = Typography;
const { TabPane } = Tabs;


const AddOrUpdateProduct = (props) => {

    const [productForm] = Form.useForm()
    const [updateValues, setUpdateValues] = useState(props.isUpdate ? props.updateCustomerValues : {});
    const [customerList, setCustomerList] = useState([]);
    const [productDimension, setProductDimension] = useState({ length: props.isUpdate && props.productData ? props.productData.product_length : 0, width: props.isUpdate && props.productData ? props.productData.product_width : 0, height: props.isUpdate && props.productData ? props.productData.product_height : 0 });
    const [productsList, setProductsList] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [uploadCustomer, setUploadCustomer] = useState();
    const [uploadData, setUploadData] = useState([]);

    useEffect(() => {
        if (props.isUpdate && props.updateProductFrom != "Menu") {
            productForm.setFieldsValue({
                customer_id: props.updateProductValues.customer_id,
                product_category: props.updateProductValues.product_category,
                _id: props.updateProductValues._id,
                product_length: props.updateProductValues.product_length,
                product_width: props.updateProductValues.product_width,
                product_height: props.updateProductValues.product_height,
                product_description: props.updateProductValues.product_description,
                product_hsn_code: props.updateProductValues.product_hsn_code,
                product_price: props.updateProductValues.product_price,
            });
            setProductDimension({ length: props.updateProductValues.product_length, width: props.updateProductValues.product_width, height: props.updateProductValues.product_height })
        } else {
            restFormFields(productForm);
            setProductDimension({ length: 0, width: 0, height: 0 })
        }

    }, [props.updateProductValues, props.isUpdate]);

    useEffect(() => {
        if (isDesktop) {
            service.GET("get_customer_data", [], {}).then(res => {
                let customerList = JSON.parse(JSON.stringify(res && res.length ? res : []));
                let customers = (customerList || []).map(m => <Select.Option key={m._id} value={m._id}>{`${m.customer_name}`}</Select.Option>);
                setCustomerList(customers);
            });
        }
    }, [props.selectedMenu])
    useEffect(() => {
        if (isMobile) {
            service.GET("get_customer_data", [], {}).then(res => {
                let customerList = JSON.parse(JSON.stringify(res && res.length ? res : []));
                let customers = (customerList || []).map(m => <Select.Option key={m._id} value={m._id}>{`${m.customer_name}`}</Select.Option>);
                setCustomerList(customers);
            });

            service.GET("get_product_data", [], {}).then(res => {
                let productList = JSON.parse(JSON.stringify(res && res.length ? res : []));
                let product_category = (productList || []).reduce((acc, obj) => {
                    if (!acc.includes(obj.product_category)) {
                        acc.push(obj.product_category)
                    }
                    return acc;
                }, []).map(m => <Select.Option key={m} value={m}>{`${m}`}</Select.Option>);
                setProductsList(productList);
            }).catch(err => {
                console.log(err)
            });
        }
    }, [props.selectedMenu]);

    const handleDimensionChange = (column, value) => {
        let obj = Object.assign({}, productDimension);
        obj[column] = value;
        setProductDimension(obj);
    }

    const renderProductSelection = () => {

        const productsCategory = (productsList || []).reduce((acc, obj) => {
            if (!acc.includes(obj.product_category)) {
                acc.push(obj.product_category)
            }
            return acc;
        }, []).map(m => <Select.Option key={m} value={m}>{`${m}`}</Select.Option>);
        return (
            <Row>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="product_category"
                        labelAlign="left" label="Product Category"
                        rules={[{ required: true, message: 'Please input Product Category!' }]}>
                        <Select showSearch placeholder="Select Product Category"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={value => {
                                let filteredProducts = productsList.filter(m => {
                                    if (value == m.product_category) {
                                        return true
                                    }
                                });
                                setFilteredProducts(filteredProducts.map(m => <Select.Option key={m._id} value={m._id}>{`${m.product_length} X ${m.product_width} X ${m.product_height}`}</Select.Option>));
                                setUpdateValues({})
                            }}
                        >
                            {productsCategory}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={spanValues}>
                    <Form.Item
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name='product_id'
                        labelAlign="left" label="Product Dimension"
                        rules={[{ required: true, message: 'Please input Product Name!' }]}>
                        <Select showSearch placeholder="Select Product Dimension"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onSelect={value => {

                                productsList.forEach(m => {
                                    if (m._id == value) {
                                        productForm.setFieldsValue({
                                            customer_id: m.customer_id,
                                            product_category: m.product_category,
                                            _id: m._id,
                                            product_length: m.product_length,
                                            product_width: m.product_width,
                                            product_height: m.product_height,
                                            product_description: m.product_description,
                                            product_hsn_code: m.product_hsn_code,
                                            product_price: m.product_price,
                                        });
                                        setProductDimension({
                                            length: m.product_length,
                                            height: m.product_height,
                                            width: m.product_width,
                                        })
                                    }
                                })

                            }}
                        >
                            {filteredProducts}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        )
    }

    const renderPrdouctForm = () => {
        return (
            <Form
                form={productForm}
                name="product"
                layout="horizontal"
                scrollToFirstError
                onFinish={(data) => handleProductChange([data, props, productForm, setProductDimension])}
                initialValues={{ ...updateValues }}
                autoComplete="off"
            >
                <Row>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="customer_id" labelAlign="left" label="Customer Name"
                            rules={[{ required: true, message: 'Please input customer!' }]}>
                            <Select showSearch placeholder="Select Customer Name"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {customerList}
                            </Select>
                        </Form.Item>
                    </Col>

                </Row>
                {(isMobile && props.isUpdate && props.updateProductFrom != "Menu") || (isMobile && !props.isUpdate) || (isDesktop) ? <Row>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="product_category" labelAlign="left" label="Product Category"
                            rules={[{ required: true, message: 'Please input Product Category!' }]}>
                            <Input style={{ ...style.ConvertToUpperCase }} />
                        </Form.Item>
                    </Col>
                </Row> : null}
                <Row>
                    <Col span={isMobile ? 24 : 8}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 12 }}
                            wrapperCol={{ span: isMobile ? 24 : 9 }}
                            name="product_length" labelAlign="left" label="Length"
                            rules={[{ required: true, message: 'Please input length!' }]}>
                            <Input type="number" onChange={e => handleDimensionChange("length", e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={isMobile ? 24 : 8}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 9 }}
                            name="product_width" labelAlign="left" label="Width"
                            rules={[{ required: true, message: 'Please input width!' }]}>
                            <Input type="number" onChange={e => handleDimensionChange("width", e.target.value)} />
                        </Form.Item>
                    </Col>
                    <Col span={isMobile ? 24 : 8}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 6 }}
                            wrapperCol={{ span: isMobile ? 24 : 9 }}
                            name="product_height" labelAlign="left" label="Height"
                            rules={[{ required: true, message: 'Please input height!' }]}>
                            <Input type="number" onChange={e => handleDimensionChange("height", e.target.value)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>

                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }} labelAlign="left" label="Product Dimension">
                            <Text strong>{`${productDimension.length} X ${productDimension.width} X ${productDimension.height}`}</Text>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    {
                        props.isUpdate ? <Col span={24}>
                            <Form.Item
                                labelCol={{ span: isMobile ? 24 : 4 }}
                                wrapperCol={{ span: isMobile ? 24 : 17 }}
                                name="_id" labelAlign="left" label="Product ID"
                                rules={[{ required: true, message: 'Please input Product ID!' }]}>
                                <Input style={{ ...style.ConvertToUpperCase }} disabled={true} />
                            </Form.Item>
                        </Col> : null
                    }
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="product_description" labelAlign="left" label="Product Description"
                            rules={[{ type: 'string', required: true, message: 'Please input valid product description!', min: 4, max: 300 }]}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="product_hsn_code" labelAlign="left" label="Product HSN Code"
                            rules={[{ required: true, message: 'Please input product HSN code!', type: "string", min: 4, max: 8 }]}>
                            <Input style={{ ...style.ConvertToUpperCase }} />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="product_cft" labelAlign="left" label="Cubic Feet"
                        >
                            <Input type="number" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item
                            labelCol={{ span: isMobile ? 24 : 4 }}
                            wrapperCol={{ span: isMobile ? 24 : 17 }}
                            name="product_price" labelAlign="left" label="Product Price"
                            rules={[{ required: true, message: 'Please input valid product price!' }]}>
                            <Input prefix="&#x20b9;" type="number" />
                        </Form.Item>
                    </Col>
                </Row>
                
                <Form.Item style={style.PopUpAddButton}>
                    <Button style={{ ...style.button }} htmlType="submit" >{props.isUpdate ? "UPDATE" : "ADD PRODUCT"}</Button>
                </Form.Item>

            </Form>
        )
    }

    const getUploadData = (data) => {
        // console.log(data)
        setUploadData(data)
    }

    const handleFileUpload = () => {
        
        let data = uploadData, finalData = [];
        data.forEach(m => {
            let obj = {};
            if (m.product_dimension && m.product_category) {
                let [length, width, height] = m.product_dimension.toLowerCase().split("x");
                obj.product_length = length.trim();
                obj.product_width = width.trim();
                obj.product_height = height.trim();
                // obj.product_dimension = `${obj.product_length} X ${obj.product_width} X ${obj.product_height}`;
                obj.product_hsn_code = m.product_hsn_code;
                obj.product_price = m.product_price || null;
                obj.customer_id = uploadCustomer;
                obj.product_category = m.product_category;
                obj.product_description = m.product_description || null;
                obj.product_cft = m.product_cft || null;
                finalData.push(obj);
            }
        })

        if(finalData && finalData.length) {
            service.UPDATE("add_multi_product_data", finalData, true).then(res => {
                props.setProductTableData();
                restFormFields(productForm);
                setProductDimension({ length: 0, width: 0, height: 0 })
                message.success('Added Successfully');
                props.closePopup()
            }).catch(err => err)
        }
        // console.log(finalData)
    }

    const renderFileUpload = () => {
        return (
            <div className="file-upload">
                <div style={{ marginBottom: "24px" }}>
                    <Row span={24}>
                        <Col span={isMobile ? 24 : 4}>
                            <Text >Customer Name</Text>
                        </Col>
                        <Col span={isMobile ? 24 : 12}>
                            <Select showSearch placeholder="Select Customer Name"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                style={{ width: "100%" }}
                                onChange={value => {
                                    setUploadCustomer(value)
                                }}
                            >
                                {customerList}
                            </Select>
                        </Col>

                    </Row>
                </div>
                <FileUploadComponent getUploadData={getUploadData} disabled={uploadCustomer ? false : true} />
                <Button onClick={handleFileUpload} disabled={uploadData && uploadData.length ? false : true} style={style.button} icon={<UploadOutlined />}>Add Product Data</Button>
            </div>
        )
    }
    return (
        <div className="add-product-container" style={{width: "100%"}}>
            <Tabs defaultActiveKey="manual">
                <TabPane tab="Manual" key="manual">
                    {isMobile && props.isUpdate && <div style={{ fontSize: "18px", fontWeight: 600, padding: "5px 0px 15px 0px" }}>Update Product Details</div>}
                    {isMobile && !props.isUpdate && <div style={{ fontSize: "18px", fontWeight: 600, padding: "5px 0px 15px 0px" }}>Add Product Details</div>}
                    {isMobile && props.isUpdate && props.updateProductFrom == "Menu" ? renderProductSelection() : null}
                    <div>
                        {renderPrdouctForm()}

                    </div>
                </TabPane>
                <TabPane tab="Upload File" key="fileUpload">
                    {renderFileUpload()}
                </TabPane>
            </Tabs>

        </div>
    )
}


const restFormFields = (productForm) => {
    productForm.setFieldsValue({
        customer_id: undefined,
        product_category: undefined,
        _id: undefined,
        product_length: undefined,
        product_width: undefined,
        product_height: undefined,
        product_description: undefined,
        product_hsn_code: undefined,
        product_price: undefined,
    })
}

const handleProductChange = (values) => {
    let [data, props, productForm, setProductDimension] = values;
    let productData = data || {};
    productData.product_category = productData.product_category.toUpperCase();
    Object.keys(productData).forEach(key => {
        if (!productData[key]) {
            productData[key] = ""
        }
    });
    console.log(productData);
    if (props.isUpdate && typeof productData == "object") {
        service.UPDATE("update_product_data", [productData]).then(res => {
            props.setProductTableData();
            restFormFields(productForm);
            setProductDimension({ length: 0, width: 0, height: 0 })
            message.success('Updated Successfully');
        }).catch(err => err);
    } else {
        service.UPDATE("add_product_data", [productData]).then(res => {
            props.setProductTableData();
            restFormFields(productForm);
            setProductDimension({ length: 0, width: 0, height: 0 })
            message.success('Added Successfully');
        }).catch(err => err)
    }
    props.closePopup()

}

const mapStateToProps = state => {
    return {
        selectedMenu: state.selectedMenu,
        updateProductFrom: state.updateProductFrom,
        updateProductValues: state.updateProductValues
    }
};

const mapDispatchToProps = dispatch => ({
    updateProductDetails: data => dispatch(ACTIONS.updateProductDetails(data)),
    setProductTableData: data => dispatch(service.fetchProductData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddOrUpdateProduct);