import { LeftOutlined, DeleteOutlined, RightOutlined } from "@ant-design/icons";
import { DatePicker, Divider, Select } from "antd";
import moment from "moment";
import React from "react";
import service from "../../../../common/service";
import "./ListCard.scss";

const currencyFormatter = require('currency-formatter');

const getListData = (data = [], currentPage = 0, pageSiz = 5) => {
    let tempData = JSON.parse(JSON.stringify(data));
    tempData = service.Sort(tempData || [], "asc", "purchase_date", true);
    tempData.forEach((m, i) => {
        m.index = i + 1
    });
    return tempData?.slice(currentPage * pageSiz, (currentPage + 1) * pageSiz)
}

export default class extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            listData: [],
            customers_list: [],
            products_list: [],
            currentPage: 0
        }
    }

    handlePrev() {
        if (this.state.currentPage > 0) {
            this.setState(state => ({
                currentPage: state.currentPage - 1,
                listData: getListData(this.state.data, state.currentPage - 1, 5)
            }))
        }
    }

    handleNext() {
        if ((this.state.currentPage + 1) * 5 < this.state.data?.length) {
            this.setState(state => ({
                currentPage: state.currentPage + 1,
                listData: getListData(this.state.data, state.currentPage + 1, 5)
            }))
        }
    }

    getPageDetails() {
        return `${(this.state.currentPage * 5) + 1} - ${(this.state.currentPage + 1) * 5} of ${this.state.data?.length}`
    }

    componentDidMount() {
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data != prevState.data) {
            return {
                ...nextProps,
                props: nextProps,
                listData: getListData(nextProps.data, 0, 5),
                currentPage: 0
            }
        }
        if (nextProps !== prevState.props) {
            return {
                props: nextProps,
                ...nextProps,
            };
        } else return null;
    }

    render() {
        return (
            <div className="list-container">
                {this.state.data?.length ? <div className="list-pagination">
                    <LeftOutlined className={`pagination-arrow prev`} onClick={this.handlePrev.bind(this)} />
                    {this.getPageDetails()}
                    <RightOutlined className={`pagination-arrow next`} onClick={this.handleNext.bind(this)} />
                </div> : <div style={{display: "flex", justifyContent: "center"}}>No Records Found!!</div>}
                {
                    (this.state.listData || []).map((obj, i) => {
                        let product_name = (this.state.products_list || []).reduce((acc, m) => {
                            if (m.value == obj.product_name) acc = m.label;
                            return acc;
                        }, "");
                        let customer_name = (this.state.customers_list || []).reduce((acc, m) => {
                            if (m.value == obj.customer_name) acc = m.label;
                            return acc;
                        }, "");
                        return (
                            <div className={`list-card ${obj.pending_amount > 0 ? " pending-container" : ""}`}>
                                <div className="list-card-row">
                                    <div className="list-card-column customer-name">{customer_name || ""}</div>
                                    <div className="list-card-column purchase-date">{obj.purchase_date && moment(obj.purchase_date).format("DD-MMM-YYYY") || ""}</div>
                                </div>
                                <div className="list-card-row">
                                    <div className="list-card-column product-name">{`#${obj.index} ${product_name}`}</div>
                                    <div className="list-card-column total">{obj.total_amount && currencyFormatter.format(obj.total_amount, { code: 'INR' }).substr(1) || "" || ""}</div>
                                </div>
                                <div className="list-card-row">
                                    <div className="list-card-column">Item Subtotal</div>
                                    <div className="list-card-column sub-total">{`${obj.quantity}${obj.unit && "(" + obj.unit + ")" || ""} x ${obj.product_price && currencyFormatter.format(obj.product_price, { code: 'INR' }).substr(1) || ""} = ₹ ${obj.total && currencyFormatter.format(obj.total, { code: 'INR' }).substr(1) || ""}`}</div>
                                </div>
                                <div className="list-card-row">
                                    <div className="list-card-column">{`Tax GST@${obj.gst}%`}</div>
                                    <div className="list-card-column gst-amount">{`₹ ${obj.gst_amount && currencyFormatter.format(obj.gst_amount, { code: 'INR' }).substr(1) || "0.00"}`}</div>
                                </div>
                                <div className="list-card-row amount-paid">
                                    <div className="list-card-column">Amount Paid</div>
                                    <div className="list-card-column amount-paid">{`₹ ${obj.amount_paid && currencyFormatter.format(obj.amount_paid, { code: 'INR' }).substr(1) || "0.00"}`}</div>
                                </div>
                                <div className="list-card-row pending-amount">
                                    <div className="list-card-column">Amount Pending</div>
                                    <div className="list-card-column pending-amount">{`₹ ${obj.pending_amount && currencyFormatter.format(obj.pending_amount, { code: 'INR' }).substr(1) || "0.00"}`}</div>
                                </div>
                                <div style={{ color: "#3F3E3E" }}>Remarks: {obj.remarks || "Nil"}</div>
                                <div className="list-card-row delete">
                                    {obj.pending_amount > 0 ? <div className="list-card-column update" onClick={e => typeof this.props.handleEdit == "function" && this.props.handleEdit(obj)}><DeleteOutlined /> Edit</div> : null}
                                    <div className="list-card-column delete" onClick={e => typeof this.props.handleDelete == "function" && this.props.handleDelete(obj._id)}><DeleteOutlined /> Delete</div>
                                </div>

                            </div>
                        )
                    })
                }
            </div>
        )
    }
}