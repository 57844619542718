import { Button, Select, Input } from "antd";
import React from "react";
import "./TicketComponent.scss";

const Option = Select;
const { TextArea } = Input;

export default class TicketComponent extends React.Component {

    _baseClassName = "ticket-component";
    constructor(props) {
        super(props);
        this.state = {
            availableTickets: this.getAvailableTickets(),
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props !== nextProps) {
            this.setState(nextProps);
        }
    }

    getAvailableTickets() {
        if(this.props.availableTickets && Array.isArray(this.props.availableTickets)) {
            return this.props.availableTickets;
        }
        return ["Ticket 1",  "Ticket 2", "Ticket 3", "Ticket 4"]
    }


    renderHeaderPortion() {
        let tickets = this.state.availableTickets.join(" | ");
        return (
            <div className={'header-portion'}>
                <div className="header-content-left"> 
                <div className="header-title">{this.props.headerTitle || "Showing Tickets For"}</div>
                {tickets ? <div className="header-sub-title">{tickets}</div> : null}
                </div>
                <div className="header-content-right">
                    <button className="button" onClick={e => {
                    if(this.props.dynamicFunction && typeof this.props.dynamicFunction == "function") {
                        this.props.dynamicFunction('stop', e)
                    }
                }}>Stop</button>
                </div>
            </div>
        )
    }

    renderMainContentTop() {
        return (
            <div className="main-content-top">
                <div className="content-left">
                    <img src={'https://upload.wikimedia.org/wikipedia/commons/9/99/Sample_User_Icon.png'} style={{width: "18px", height: "18px"}}/>
                    <div>{`${this.props.contentHeaderlabel || "TICKET ID"}: ${this.state.ticketId || '#0777728765'}`}</div>
                </div>
                <div className="content-right">
                    <button className="button ticket-name">{this.state.ticketName || "Ticket Name"}</button>
                    <button className="button open-ticket">{"Open Ticket"}</button>
                </div>
            </div>
        )
    }

    renderDataDropDown(obj, index) {
        let { value, defaultValue } = obj;
        if(value && Array.isArray(value)) {
            return (
                <Select  
                placeholder={obj.placeholder || "Select"}
                defaultValue={defaultValue} 
                onChange={val => {
                    if(this.props.dynamicFunction && typeof this.props.dynamicFunction == "function") {
                        this.props.dynamicFunction(obj.label, val)
                    }
                }}>
                    {
                        (value || []).map((option, i) => {
                            if(typeof option == "object") {
                                return <Option key={i} value={option.value || option.label}>{option.label || option.value}</Option>
                            }

                            return <Option key={i} value={option}>{option}</Option>
                        })
                    }
                </Select>
            )
        }
        return null
    }

    renderContent(data) {
        return data.map((obj, i) => {
                return (
                    <div className="content-row-item" key={i}>
                        {obj.icon ? <img src={obj.icon} className="row-item" style={{width: "18px", height: "18px"}} /> : null}
                        {obj.label && obj.showLabel != false ? <div className="row-item label">{obj.label + ':'}</div> : null}
                        {obj.label && obj.value && typeof obj.value == "string" && obj.hasSelect != true ? <div className="row-item value">{obj.value}</div> : null}
                        {obj.hasSelect == true ? this.renderDataDropDown(obj, i) : null}
                    </div>
                )
            })
    }

    renderMainErrorMsg(errorMsg) {
        if(errorMsg) {
            return (
                <div className="main-content-error-container">{errorMsg}</div>
            )
        }
    }

    renderMainContentData() {

        let leftData = (this.state.mainContentData || []).filter(m => m.align != "right");
        let rightData = (this.state.mainContentData || []).filter(m => m.align == "right");
        return (
            <div className="main-content-data">
                <div className="content-left">
                    {this.renderContent(leftData)}
                </div>
                <div className="content-right">
                    {this.renderContent(rightData)}
                </div>
            </div>
        )
    }

    renderMainContent() {
        return (
            <div className="main-content">
                {this.renderMainContentTop()}
                {this.renderMainContentData()}
                {this.props.showMainContentError  && this.renderErrorMsg(this.props.mainContentErrorMessage) || null}
            </div>
        )
    }

    renderFooterTop() {
        return (
            <div className="footer-top-content">
                <button onClick={e => {
                    if(this.props.dynamicFunction && typeof this.props.dynamicFunction == "function") {
                        this.props.dynamicFunction((this.props.resolveText || "resolve"), e)
                    }
                }}>{this.props.resolveText || "Resolve"}</button>
                {this.state.showCustomFooter != false && this.props.renderCustomFooter && typeof this.props.renderCustomFooter == "function" ? <div className="custom-footer-content">{this.props.renderCustomFooter()}</div> : null }
            </div>
        )
    }
    renderFooterBottom() {
        return (
            <div className="footer-bottom-content">
                <div className="comments-container">
                <TextArea rows={4} onChange={(e) => {
                    if(this.props.dynamicFunction && typeof this.props.dynamicFunction == "function") {
                        this.props.dynamicFunction('comment', e.target.value)
                    }
                }}/>
                </div>
                <button onClick={e => {
                    if(this.props.dynamicFunction && typeof this.props.dynamicFunction == "function") {
                        this.props.dynamicFunction('submit', e)
                    }
                }}>Submit</button>
            </div>
        )
    }

    renderFooter() {
        return (
            <div className="footer-content">
                {this.renderFooterTop()}
                {this.props.showFooterError  && this.renderErrorMsg(this.props.footerErrorMessage) || null}
                {this.renderFooterBottom()}
            </div>
        )
    }
    renderTicketsInfo() {
        return (
            <div className={this._baseClassName + '-main-container'}>
                {this.renderHeaderPortion()}
                {this.renderMainContent()}
                {this.state.showCustomMainContent != false && this.props.renderCustomMainContent && typeof this.props.renderCustomMainContent == "function" ? <div className="custom-main-content">{this.props.renderCustomMainContent()}</div> : null }
                {this.renderFooter()}
            </div>
        )
    }

    render() {
        return (
            <div className={this._baseClassName + ' ' + (this.props.className && this.props.className || '') }>
                {this.renderTicketsInfo()}
            </div>
        )
    }
 }