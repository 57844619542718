import { Modal } from "antd";
import { useState } from "react";

const ModalComponent = (props) => {
    const [modal, showModal] = useState(props.visible);
    const handleOkay = (value) => {
        showModal(false)
    }
    const handleCancel = (value) => {
        showModal(false)
    }

    const handleClose = (value) => {
       props.onClose && props.onClose()
    }
    return (
        <div className="modal-container"> 
            <Modal footer={null} width={"70%"} style={props.style ? props.style : {}} visible={modal} onOk={handleOkay} onCancel={handleCancel} 
            title={props.title ? props.title : null} maskClosable={false} afterClose={handleClose}>
                {
                    props.children ? props.children : null
                }
            </Modal>
        </div>
    )
}

export default ModalComponent;