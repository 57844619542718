import React, { useState } from 'react';
import Countdown, { zeroPad as zeroPadTime } from 'react-countdown';
import { Space } from 'antd';
import styles from './authentication.module.css';

const Timer = (props) => {
  const [duration, setDuration] = useState(
    props.duration || Date.now() + 100000
  );

  const renderTimerContent = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return props.renderExpiredContent || null;
    } else {
      return (
        <span className={styles["timer-message"]}>
          {`${props.prefix || ''} ${zeroPadTime(minutes)}:${zeroPadTime(
            seconds
          )} ${props.suffix || ''}`}
        </span>
      );
    }
  };
  return (
    <div className={props.className || '' + ' ' + styles['timer-container']}>
      <Countdown date={duration} renderer={renderTimerContent} />
      {props.showResend != false ? (
        <Space className={styles['timer-content']}>
          {props.resendContent ? (
            <div className={styles['resend-content']}>
              {props.resendContent}
            </div>
          ) : null}
          <div className={styles['resend-label']}>Resend</div>
        </Space>
      ) : null}
    </div>
  );
};

export default Timer;
