import React from 'react';
import { Form, Input } from 'antd';
import Validators from './Validators';

const Password = () => {
  return (
    <>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          { required: true, message: 'Please input your password!' },
          Validators.validatePassword,
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        label="Confirm Password"
        name="confirmPassword"
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: 'Please input your confirm password!',
          },
          Validators.validateConfirmPassword,
        ]}
      >
        <Input.Password />
      </Form.Item>
    </>
  );
};

export default Password;
