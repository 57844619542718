import React, { useEffect, useState } from 'react';
import OTPInput from './OTPInput';
import Login from './Login';
import Signup from './Signup';
import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
import WrapperComponent from './WrapperComponent';
import './common.css';
import 'antd/dist/antd.css';

const components = {
  "Login": Login,
  "Signup": Signup,
  "ForgotPassword": ForgotPassword
}
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentComponent: this.props.component,
      customProps: {},
    };

    this.assignComponent = this.assignComponent.bind(this);
  }

  assignComponent(comp, customProps) {
    this.setState({
      currentComponent: comp,
      customProps: customProps,
    });
  }

  render() {
    let Component = null;
    if (this.state.currentComponent == 'Login') {
      Component = WrapperComponent(
        Login,
        this.assignComponent,
        this.state.customProps
      );
    } else if (this.state.currentComponent == 'Signup') {
      Component = WrapperComponent(
        Signup,
        this.assignComponent,
        this.state.customProps
      );
    } else if (this.state.currentComponent == 'OTP') {
      Component = WrapperComponent(
        OTPInput,
        this.assignComponent,
        this.state.customProps
      );
    } else if (this.state.currentComponent == 'changePassword') {
      Component = WrapperComponent(
        ChangePassword,
        this.assignComponent,
        this.state.customProps
      );
    } else if (this.state.currentComponent == 'ForgotPassword') {
      Component = WrapperComponent(
        ForgotPassword,
        this.assignComponent,
        this.state.customProps
      );
    }
    return <>{Component ? <Component /> : null}</>;
  }
}
