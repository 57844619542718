import { Upload, message, Button, Row, Col } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import style from "../css/common.js"

const { Dragger } = Upload;

const beforeUpload = (file) => {
    const isXlsx = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    if (!isXlsx) {
        message.error('You can only upload JPG/PNG file!');
    }
    return true;
}

const handleChange = (info, getUploadData) => {
    if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
        getUploadData(info.file.response)
        message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
    }
}


const FileUploadComponent = ({ getUploadData, disabled }) => {
    const props = {
        name: 'file',
        action: 'http://localhost:8000/file',
        headers: {
            authorization: 'authorization-text',
        },
        maxCount: 1,
        multiple: false,
        onChange(info) {
            handleChange(info, getUploadData)
        },
        beforeUpload(file) {
            beforeUpload(file)
        },
    };

    return (
        <Row span={24} style={{justifyContent: "center", marginBottom: "24px"}}>
            <Col span={24} style={{height: "100%"}}>
                <Dragger {...props} disabled={disabled}>
                    <Button disabled={disabled} icon={<UploadOutlined />} >Click to Upload</Button>
                </Dragger>
            </Col>
        </Row>
    )
}

export default FileUploadComponent;