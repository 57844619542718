import React from "react";
import {  StyleSheet, View, Text, Image } from '@react-pdf/renderer';
import tick from "../../images/check-mark.png"

const currencyFormatter = require('currency-formatter');

const styles = StyleSheet.create({
    customer_container: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    top_header: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        padding: "0px 5px",
        borderBottom: "1px solid #000"
    },
    top_body: {
        display: "flex",
        width: "100%",
        // "min-height": "300px",
        height: "380px", 
        flexDirection: "column",
        padding: "5px 0px"
    },
    product: {
        display: "flex",
        width: "100%",
        flexDirection: "row"
    },
    top_portion: {
    },
    bottom_portion: {
        display: "flex",
        width: "100%",
        flexDirection: "column",
    },
    bottom_header: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        padding: "0px 5px",
        borderBottom: "1px solid #000",
        borderTop: "1px solid #000"
    },
    bottom_body: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        padding: "0px 5px",
        borderBottom: "1px solid #000",
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        borderBottom: "1px solid #000",
    }
});

const ProductDetails = (props) => {

    let product =  [];
    let {product_details, gst_details, material_details, summary_details, customer_details} = props.pdfInfo || {};
    let selectedState = customer_details?.state_code?.split(" ")?.[0]
    return (
        <View style={styles.customer_container}>
            <View style={styles.top_header}>
                <Text style={{ width: 50, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>S.no</Text>
                <Text style={{ flex: 1, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>HSN CODE</Text>
                <Text style={{ flex: 4, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Description </Text>
                <Text style={{ flex: 1.5, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Size </Text>
                <Text style={{ flex: 1, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Price</Text>
                <Text style={{ flex: 0.5, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Qty</Text>
                {summary_details.enableCFT ? <Text style={{ flex: 1, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Cu.ft</Text> : null}
                <Text style={{ flex: 1.5, padding: "3px 0px", display: "flex", textAlign: "center" }}>Total</Text>
            </View>
            <View style={{...styles.top_body}}>
                {
                    product_details.map((item, i) => {
                        return (
                            <View style={styles.product} key={i + 1}>
                                <Text style={{ width: 50, padding: "3px 0px 3px 0px", display: "flex", textAlign: "center", }}>{i + 1}</Text>
                                <Text style={{ flex: 1, padding: "3px 0px 3px 0px", display: "flex", textAlign: "center", }}>{item.product_hsn_code}</Text>
                                <Text style={{ flex: 4, padding: "3px 0px 3px 0px", display: "flex", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", "white-space": "nowrap" }}>{`${item.product_description}`}</Text>
                                <Text style={{ flex: 1.5, padding: "3px 0px 3px 0px", display: "flex", textAlign: "center", }}>{`${item.product_name}`}</Text>
                                <Text style={{ flex: 1, padding: "3px 0px 3px 0px", display: "flex", textAlign: "center", }}>{currencyFormatter.format(item.product_price, { code: 'INR' }).substr(1)}</Text>
                                <Text style={{ flex: 0.5, padding: "3px 0px 3px 0px", display: "flex", textAlign: "center", }}>{item.quantity}</Text>
                                {summary_details.enableCFT ? <Text style={{ flex: 1, padding: "3px 0px 3px 0px", display: "flex", textAlign: "center", }}>{item.product_total_cft}</Text> : null}
                                <Text style={{ flex: 1.5, padding: "3px 5px 3px 0px", display: "flex", textAlign: "right", }}>{currencyFormatter.format(item.product_total_price, { code: 'INR' }).substr(1)}</Text>
                            </View>
                        )
                    })
                }
            </View>
            <View style={{...styles.bottom_header, borderBottom: "unset"}}>
                { summary_details.enableCFT ? <Text style={{ flex: 8.5, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Total</Text> : null }
                <Text style={{ flex: 1, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>{summary_details.total_quantity}</Text>
                { summary_details.enableCFT ? <Text style={{ flex: 1, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>{summary_details.total_cft}</Text> : null }
                
                {/* <Text style={{ flex: summary_details.enableCFT ? 1.5 : 2.5, padding: "3px 0px", display: "flex", textAlign: "right" }}>{" "}</Text> */}
                <Text style={{ flex: 1.5, padding: "3px 0px", display: "flex", textAlign: "right" }}>{currencyFormatter.format(summary_details.gross_total, { code: 'INR' }).substr(1)}</Text>
            </View>
            <View style={styles.bottom_portion}>
                <View style={styles.bottom_header}>
                    <Text style={{ flex: 6.68, padding: "3px 0px", display: "flex", textAlign: "center",  }}>Material Type</Text>
                    <Text style={{ width: 85, padding: "3px 0px", display: "flex", textAlign: "center",  }}>{material_details["pineWood"] ? "Pine Wood" : (material_details["plyWood"] ? "Ply Wood" : (material_details["rubberWood"] ? "Rubber Wood" : ""))}</Text>
                    {/* <Text style={{ flex: 2, padding: "3px 0px", display: "flex", textAlign: "center", borderRight: "1px solid #000" }}>Gross Total</Text>
                    <Text style={{ flex: 1.4, padding: "3px 0px", display: "flex", textAlign: "right" }}>{currencyFormatter.format(summary_details.gross_total, { code: 'INR' }).substr(1)}</Text> */}
                    {selectedState == "33" ? <Text style={{ flex: 2, padding: "3px 0px", display: "flex", textAlign: "center",  }}>{`CGST: ${gst_details.cgst}%`}</Text> : <Text style={{ flex: 2, padding: "3px 0px", display: "flex", textAlign: "center",  }}>{`IGST: ${gst_details.igst}%`}</Text>}
                    {selectedState == "33" ? <Text style={{ flex: 1.48, padding: "3px 0px", display: "flex", textAlign: "right" }}>{currencyFormatter.format(summary_details.cgst_value, { code: 'INR' }).substr(1)}</Text> : 
                    <Text style={{ flex: 1.48, padding: "3px 0px", display: "flex", textAlign: "right" }}>{currencyFormatter.format(summary_details.igst_value, { code: 'INR' }).substr(1)}</Text>}
                </View>
                <View>
                    {selectedState == "33" ? <View style={styles.bottom_body}>
                        {/* <Text style={{ flex: 6.8, padding: "3px 0px", display: "flex", textAlign: "center",  }}>Rubber Wood</Text> */}
                        {/* <View style={{ width: 85, padding: "3px 0px", display: "flex", justifyContent: "center", alignItems: "center",  }}>
                            { material_details["rubberWood"] ? <Image style={{ width: "8px" }} src={tick} /> : null }
                        </View> */}
                        <Text style={{ flex: 7, padding: "3px 0px", display: "flex", textAlign: "right",  }}>{`SGST: ${gst_details.sgst}%`}</Text>
                        <Text style={{ flex: 1.48, padding: "3px 0px", display: "flex", textAlign: "right" }}>{currencyFormatter.format(summary_details.sgst_value, { code: 'INR' }).substr(1)}</Text>
                    
                    </View> : null}
                    <View style={styles.bottom_body}>
                        {/* <Text style={{ flex: 6.8, padding: "3px 0px", display: "flex", textAlign: "center",  }}>Pine Wood</Text> */}
                        {/* <View style={{ width: 85, padding: "3px 0px", display: "flex", justifyContent: "center", alignItems: "center",  }}>
                            { material_details["pineWood"] ? <Image style={{ width: "8px" }} src={tick} /> : null }
                        </View> */}
                        <Text style={{ flex: 7, padding: "3px 0px", display: "flex", textAlign: "right",  }}>Total Amount</Text>
                        <Text style={{ flex: 1.48, padding: "3px 0px", display: "flex", textAlign: "right" }}>{currencyFormatter.format(summary_details.total_amount, { code: 'INR' }).substr(1)}</Text>
                    
                     </View>
                    {/* <View style={styles.bottom_body}>
                        <Text style={{ flex: 6.5, padding: "3px 0px", display: "flex", textAlign: "center",  }}>Ply Wood</Text>
                        <View style={{ width: 85, padding: "3px 0px", display: "flex", justifyContent: "center", alignItems: "center",  }}>
                            { material_details["plyWood"] ? <Image style={{ width: "8px" }} src={tick} /> : null }
                        </View>
                        <Text style={{ flex: 3.46, padding: "3px 0px", display: "flex", textAlign: "right" }}>{" "}</Text>
                    
                    </View> */}
                </View>
            </View>
            <View style={styles.footer}>
                <Text style={{ width: 100, padding: "3px 5px", display: "flex", textAlign: "left", borderRight: "1px solid #000" }}>Amount in Words : </Text>
                <Text style={{ padding: "3px 5px", display: "flex", textAlign: "left" }}>{summary_details.amount_in_words}</Text>
            </View>
        </View>
    )
};
 
export default ProductDetails;