import React from 'react';
import { Space } from 'antd';
import styles from './authentication.module.css';

const OptionalComponent = (props) => {
  const handleClick = (e) => {
    if (typeof props.handleFunction == 'function') {
      props.handleFunction(e);
    }
  };
  return (
    <Space wrap={true}>
      <span>{props.optionalMessage}</span>
      <span onClick={handleClick} className={styles['optional-button']}>
        {props.OptionalContent}
      </span>
    </Space>
  );
};

export default OptionalComponent;
