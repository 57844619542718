import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import styles from './authentication.module.css';
import OptionalComponent from './OptionalComponent';
import Password from './PasswordFields';
import Validators from './Validators';

export default class extends React.Component {
  signupForm = React.createRef();
  constructor(props) {
    super(props);
    this.state = {};

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(formData) {
    this.props.setModule('OTP');
  }

  componentDidMount() {}

  render() {
    return (
      <div className={styles['container']} style={this.props.style}>
        <div className={styles['container-body']}>
          <h3 className={styles['authentication-title']}>Create an Account</h3>
          <Form
            name="signup"
            ref={this.signupForm}
            initialValues={{ remember: true }}
            onFinish={this.handleSubmit}
            autoComplete="off"
            labelCol={{
              span: 24,
            }}
            wrapperCol={{
              span: 24,
            }}
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Username is required',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="E-mail"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'E-mail is required',
                },
                Validators.validateEmail,
              ]}
            >
              <Input />
            </Form.Item>

            <Password />

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Sign Up
              </Button>
            </Form.Item>
          </Form>
          <OptionalComponent
            optionalMessage="Already Have an Account? "
            OptionalContent="Login"
            handleFunction={e => this.props.setModule("Login")}
          />
        </div>
      </div>
    );
  }
}
