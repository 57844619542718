import { combineReducers } from "redux";
import ACTIONS from "./actions";

const defaultState = {
  menuCollapsed: true,
  customerDetails: {},
  customerTableData: [],
  productTableData: [],
  selectedMenu: "login",
  user: {},
  updateCustomerValues: {}
}
const reducer = (state = {...defaultState}, action) => {
  switch (action.type) {
    case ACTIONS.Types.MENU_COLLAPSED: {
      return { ...state, menuCollapsed: action.payload }
    }

    case ACTIONS.Types.SET_PRODUCT_TABLE_DATA: {
      return {...state, productTableData: action.payload}
    }
    case ACTIONS.Types.SET_CUSTOMER_TABLE_DATA: {
      return {...state, customerTableData: action.payload}
    }
    case ACTIONS.Types.SET_CURRENT_MENU: {
      return {...state, selectedMenu: action.payload}
    }
    case ACTIONS.Types.SET_USER_DETAILS: {
      return {...state, user: action.payload}
    }
    case ACTIONS.Types.UPDATE_CUSTOMER_DETAILS: {
      return {...state, updateCustomerValues: action.payload}
    }
    case ACTIONS.Types.UPDATE_PRODUCT_DETAILS: {
      return {...state, updateProductValues: action.payload}
    }
    case ACTIONS.Types.UPDATE_CUSTOMER_DETAILS_FROM: {
      return {...state, updateCustomerFrom: action.payload}
    }
    case ACTIONS.Types.UPDATE_PRODUCT_DETAILS_FROM: {
      return {...state, updateProductFrom: action.payload}
    }

    default : {
      return state
    }
  }
}



export default reducer;