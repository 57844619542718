import React, { useEffect, useState, useRef } from "react";
import AntdTableComponent from './AntdTableComponent';
import { Button, Input, Tooltip, Popconfirm, message, Typography, DatePicker, Switch, Card, Dropdown, Menu, Row, Col, Modal, Space } from "antd";
import { connect } from "react-redux";
import service from "../common/service";
import { CloudDownloadOutlined, CloudSyncOutlined, SearchOutlined, EllipsisOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDownLoader from "./PdfComponent/PdfDownLoader";
import style from "../css/common.js";
import loader from "../images/loader.gif";
import LoaderComponent from "./LoaderComponent";
import { isDesktop, isMobile } from 'react-device-detect';
import {blobToBase64String} from 'blob-util';
import { Select } from 'antd';
import moment from "moment";

const { Option } = Select;

const currencyFormatter = require('currency-formatter');
const FileSaver = require('file-saver');

const { Text } = Typography;
let pdf_flag = true;
const SalesReportComponent = (props) => {

    const cardRef = useRef(null);
    const [isDataChange, renderNull] = useState(false);
    const [filterType, setFilterType] = useState("date");
    const [filterStatus, setFilterStatus] = useState("All");
    const [salesReportData, setSalesReportData] = useState([]);
    const [salesData, setSalesData] = useState([]);
    const [openPdf, setOpenpdf] = useState(false);
    const [pdfInfo, setPdfInfo] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [cardData, changeCardData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const salesDesktopTableConfig = {
        showTotalColumns: true,
        tableSize: "small",
        scroll: true,
        scrollX: 900,
        scrollY: 600,
        pageSize: 10,
        showIndex: false,
        indexTitle: "S.NO",
        showPagination: true,
        pageOptions: false,
        rowSelection: false,
        rowSelectionChange: function(keys, selectedRows) {
            console.log(keys, selectedRows)
        },
        enableRadioButton: false,
        selectedRowKeys: "selectedFilters",
        disableRowsSelection: [],
        showTableLegend: false,
        columns: [
            {
                title: "INVOICE NUMBER",
                dataIndex: "formatted_invoice_number",
                nullText: "NA",
                sort: true,
                width: 70,
                showFilter: false,
                fixed: 'left',
            },
            {
                title: "CUSTOMER NAME",
                dataIndex: "customer_name",
                nullText: "NA",
                sort: true,
                width: 200,
                showFilter: false,
            },
            {
                title: "INVOICE DATE",
                dataIndex: "invoice_date_formatted",
                nullText: "NA",
                sort: true,
                width: 80,
                showFilter: false,
            },
            {
                title: "AMOUNT",
                dataIndex: "total_amount",
                nullText: "NA",
                sort: false,
                width: 80,
                showFilter: false,
            },
            {
                title: "AMOUNT IN WORDS",
                dataIndex: "amount_in_words",
                nullText: "NA",
                sort: false,
                width: 240,
                showFilter: false,
            },
            {
                title: "STATUS",
                dataIndex: "status",
                sort: false,
                width: 50,
                showFilter: false,
                align: "center",
                render: (text, record, index) => {
                    let color;
                    console.log(text, record)
                    if (text == "Draft") {
                        color = "#000"
                    } else if (text == "Approved") {
                        color = "#00af80"
                    } else if (text == "Rejected") {
                        color = "#f37070"
                    }
                    return (
                        <div className="table-options" style={{ ...style.TableIconsContainer }}>
                            <Popconfirm
                                placement="topRight"
                                title="Update Invoice Status"
                                cancelText="Reject"
                                okText="Approve"
                                disabled={text == "Approved" || text == "Rejected"}
                                onConfirm={e => handleStatus("Approved", record)}
                                onCancel={e => handleStatus("Rejected", record)}
                            >
                                <span>
                                    <Text key={index} style={{ color: color, cursor: "pointer" }}>
                                        {text}
                                    </Text>
                                </span>
                            </Popconfirm>
                        </div>
                    )
                }
            },
            {
                title: "PRINT",
                sort: false,
                width: 60,
                showFilter: false,
                fixed: 'right',
                align: "center",
                render: (text, record, index) => {
                    return (
                        <div className="table-options" style={{ ...style.TableIconsContainer }}>
                            <Tooltip placement="top" title={"Click to download Invoice"}>
                                <Button disabled={record.status == "Draft"} onClick={e => handlePdfDownload(record)} icon={<CloudDownloadOutlined />} style={{ ...style.TableIcons, backgroundColor: "#00af80", opacity: record.status == "Draft" ? 0.4 : 1 }} />
                            </Tooltip>
                        </div>
                    )
                }
            },
        ]
    }
    const salesMobileTableConfig = {
        showTotalColumns: true,
        tableSize: "small",
        scroll: false,
        scrollX: 900,
        scrollY: 600,
        pageSize: 25,
        showIndex: false,
        indexTitle: "S.NO",
        showPagination: true,
        pageOptions: false,
        rowSelection: false,
        enableRadioButton: false,
        selectedRowKeys: "selectedFilters",
        disableRowsSelection: [],
        showTableLegend: false,
        columns: [
            {
                title: "CUSTOMER NAME / INVOICE NUMBER",
                sort: false,
                width: 350,
                showFilter: false,
                render: (text, record, index) => {
                    return (
                        <div className="table-options">
                            <Tooltip placement="top" title={record.customer_name}>
                                <div style={{ fontSize: "11px", color: "#ccc" }}>{record.customer_name}</div>
                                <div style={{ fontSize: "20px" }}>{record.invoice_number}</div>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: "OPTIONS",
                sort: false,
                width: 10,
                showFilter: false,
                render: (text, record, index) => {
                    return (
                        <div className="table-options" style={{ ...style.TableIconsContainer }}>
                            <Tooltip placement="top" title={"Click to View Invoice Details"}>
                                <Button style={{ backgroundColor: "#ffd666", border: "unset", color: "#000"  }} onClick={e => handleViewDetails(text, record, index)}>View Details</Button>
                            </Tooltip>
                        </div>
                    )
                }
            },
        ]
    }

    useEffect(() => {
        getSalesData()
    }, []);

    useEffect(() => {
        renderNull(!isDataChange)
    }, [salesReportData]);

    const handlePdfDownload = (record) => {
        pdf_flag = true;
        let data = salesData;
        let obj;
        data.forEach(m => {
            if (m.invoice_number == record.invoice_number) {
                obj = Object.assign({}, m);
            }
        });

        setPdfInfo(obj);
        setOpenpdf(true);
        setLoading(true);
    }

    const getSalesData = () => {
        service.GET("get_sales_data", [], {}).then(res => {
            let data = Array.isArray(res) && res.length ? res : [];
            let finalData = data.map((m, i) => {
                let obj = Object.assign({}, m);
                var date = new Date(m.invoice_date);
                let dd = String(date.getDate()).padStart(2, '0');
                let mm = String(date.getMonth() + 1).padStart(2, '0');
                let yyyy = date.getFullYear();
                const current_date = `${dd}/${mm}/${yyyy}`;
                if(m.annual_year) {
                    obj.formatted_invoice_number = m.invoice_number + "/" + m.annual_year;
                } else {
                    obj.formatted_invoice_number = m.invoice_number;
                }
                obj["customer_name"] = m.customer_details.customer_name;
                obj["invoice_date_formatted"] = current_date;
                obj["total_amount"] = `₹    ${currencyFormatter.format(m.summary_details.total_amount, { code: 'INR' }).substr(1)}`;
                obj["amount_in_words"] = m.summary_details.amount_in_words;
                obj["key"] = i;
                return obj;
            });
            finalData = service.multiLevelSort(finalData, [
                
                {
                    field: "invoice_date",
                    order: 'desc'
                },
                {
                    field: "invoice_number",
                    order: 'desc'
                },
                
            ]);
            if(cardData && cardData.length) {
                let updatedData = finalData.filter(m => m.invoice_number == cardData[0].invoice_number);
                changeCardData(updatedData);
                setTimeout(()=> cardRef.current.scrollIntoView(), 1000)
            }
            setSalesReportData(finalData);
            setSalesData(finalData)
            renderNull(false)
        }).catch(err => err);
    }
    const handleStatus = (value, record) => {
        
        let data = salesData.filter(m => m.invoice_number == record.invoice_number);
        if (data && data.length && value) {
            setSalesReportData([])
            service.UPDATE("update_sales_data", { _id: data[0]["invoice_number"], status: value }).then(res => {
                getSalesData();
                message.success('Invoice Updated')
                setIsModalVisible(false);
            }).catch(err => {
                console.log(err)
                setIsModalVisible(false);
            })

        }
    }

    const handleSearch = (e) => {

        let value = e.target.value;
        let data = [...salesData];

        let finalData = data.filter(m => m.invoice_number.toString().toLowerCase().indexOf(value.toLowerCase()) > -1 || m.customer_name.toLowerCase().indexOf(value.toLowerCase()) > -1);
        finalData = service.multiLevelSort(finalData, [
            {
                field: "invoice_date",
                order: 'desc'
            },
            {
                field: "invoice_number",
                order: 'desc'
            }
        ]);
        if (value.length) {

            setSalesReportData(finalData.length ? finalData : salesData);
        } else {
            setSalesReportData(finalData);
        }

        renderNull(true);
    }
    const filterbyStatus = (val) => {
        let data = [...salesData];
        let finalData = [...salesData];
        if (val != "All") {
            finalData = data.filter(m => m.status == val);
        }
        finalData = service.multiLevelSort(finalData, [
            {
                field: "invoice_date",
                order: 'desc'
            },
            {
                field: "invoice_number",
                order: 'desc'
            }
        ]);
        setSalesReportData(finalData);
        setFilterStatus(val);
        setFilterType("date")
        renderNull(true);
    }

    const handleExtraMenu = (record, value) => {
        if (value.key == "update") {
            changeCardData([record])
            setIsModalVisible(true);
        } else if (value.key == "download") {
            handlePdfDownload(record);
            changeCardData([]);
        }
    }

    const handleViewDetails = (value, record, index) => {
        let data = salesReportData.filter(m => m._id == record._id);
        changeCardData(data);
        cardRef.current.scrollIntoView()
    }

    const handleApproved = () => {

        let record = cardData && cardData[0]
        if (record) {
            handleStatus("Approved", record)
        }
    };

    const handleReject = () => {
        let record = cardData && cardData[0]
        if (record) {
            handleStatus("Rejected", record)
        }
    };

    const extraMenu = (m) => {
        return (
            <Menu onClick={e => handleExtraMenu(m, e)}>
                {m.status == "Draft" ? <Menu.Item key="update" icon={<CloudSyncOutlined />}>
                    <div>Update Status</div>
                </Menu.Item> : null}
                {m.status != "Draft" ? <Menu.Item key="download" icon={<CloudDownloadOutlined />}>
                    <div>Download</div>
                </Menu.Item> : null}
            </Menu>
        )
    }
    const renderExtra = (m) => {
        let bgColor;
        if (m.status == "Draft") {
            bgColor = "#000";
        } else if (m.status == "Approved") {
            bgColor = "#fff"
        } else if (m.status == "Rejected") {
            bgColor = "#fff";
        }
        return (
            <Dropdown overlay={extraMenu(m)} trigger={['click']} placement="bottomRight" arrow>
                <EllipsisOutlined key="ellipsis" style={{ color: bgColor, transform: "rotate(90deg)" }} />
            </Dropdown>
        )
    }
    const renderCard = () => {
        return cardData.map((m, i) => {
            let color, bgColor;
            if (m.status == "Draft") {
                bgColor = "#ffd666";
                color = "#000";
            } else if (m.status == "Approved") {
                bgColor = "#00af80"
                color = "#fff";
            } else if (m.status == "Rejected") {
                bgColor = "#f37070";
                color = "#fff";
            }
            return (
                <Card
                    title={
                        <div>
                            <div style={{ fontSize: "20px", color: color }}>{m.invoice_number}</div>
                            <div style={{ fontSize: "12px", fontWeight: 400, color: color }}>{m.invoice_date_formatted}</div>
                        </div>}
                    extra={renderExtra(m)}
                    headStyle={{ padding: "0px 10px", backgroundColor: bgColor }}
                    bodyStyle={{ padding: "10px 10px" }}
                    style={{ margin: "10px 0px" }}
                >
                    <Row style={{ padding: "5px 0px" }}>
                        <Col span={24}>
                            <Text>{m.customer_name ? m.customer_name : "Not Available"}</Text>
                        </Col>
                    </Row>
                    <Row style={{ padding: "5px 0px" }}>

                        <Col span={24}>
                            <Text style={{ fontSize: "20px", fontWeight: 500, color: m.status == "Draft" ? "#000" : bgColor }}>{m.total_amount ? m.total_amount : "Not Available"}</Text>
                        </Col>
                    </Row>
                    <Row style={{ padding: "5px 0px" }}>
                        <Col span={24}>
                            <Text>{m.amount_in_words ? m.amount_in_words : "Not Available"}</Text>
                        </Col>
                    </Row>
                    <Row style={{ padding: "5px 0px" }}>
                        <Col span={4}>
                            <Text style={{}}>{"Status :- "}</Text>
                        </Col>
                        <Col span={20}>
                            <Text style={{ fontSize: "15px", fontWeight: 500, color: m.status == "Draft" ? "#000" : bgColor }}>{m.status ? m.status : "Not Available"}</Text>
                        </Col>
                    </Row>

                </Card>
            )
        });
    }

    const renderCardDetails = React.useMemo(renderCard, [cardData])

    const handleDateFilter = (momentValues, dateStrings) => {
        
        let finalData = [];
        if(filterType == "date") {
            finalData = salesData.filter(m => {
                let date = new Date(moment(m.invoice_date).format("YYYY-MM-DD"));
                let selectedDate = new Date(dateStrings)
                if(dateStrings == "") {
                    return true
                }
                if((m.status == filterStatus || filterStatus == "All") && Date.parse(date) == Date.parse(selectedDate)) {
                    return true
                }
            })
        } else {
            finalData = salesData.filter(m => {
                let date = new Date(moment(m.invoice_date).format("YYYY-MM-DD"));
                let start = new Date(dateStrings[0]), end = new Date(dateStrings[1]);
                if(dateStrings[0] == "" || dateStrings[1] == "") {
                    return true
                }
                if((m.status == filterStatus || filterStatus == "All") && Date.parse(date) >= Date.parse(start) && Date.parse(date) <= Date.parse(end) ) {
                    return true
                }
            })
        }
        setSalesReportData(finalData);
        renderNull(true);
    }


    return (
        <div className="sales-container" style={{ width: "100%" }}>
            
            <div style={{ display: "flex", justifyContent: "space-between", padding: "0px 24px", alignItems: "center" }}>
                <div style={{ width: "10%" }}>
                    <Select
                        placeholder="Select to filter..."
                        optionFilterProp="children"
                        onChange={filterbyStatus}
                        defaultValue="All"
                        size="small"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                    >
                        <Option value="All">All</Option>
                        <Option value="Approved">Approved</Option>
                        <Option value="Draft">Draft</Option>
                        <Option value="Rejected">Rejected</Option>
                    </Select>
                </div>
                <div style={{display: "flex", justifyContent: "flex-start", flex: 1}}>
                    <div style={{ width: "20%", padding: "0px 24px" }}>
                        <Select
                            onChange={value => {
                                setFilterType(value);
                            }}
                            defaultValue={filterType}
                            value={filterType}
                            size="small"
                            style={{ width: "100%" }}
                        >
                            <Option value="date">Date</Option>
                            <Option value="between">Between</Option>
                        </Select>
                    </div>
                    <div style={{width: "auto"}}>
                    {filterType == "date" ? <DatePicker
                        size="small"
                        showNow={true}
                        disabledDate={current => current && current > moment().endOf('day')}
                        onChange={handleDateFilter} /> : <DatePicker.RangePicker
                        size="small"
                        showToday
                        disabledDate={current => current && current > moment().endOf('day')}
                        onChange={handleDateFilter} />}
                    </div>
                </div>
                <div style={{ ...style.CustomerSearch, width: "25%" }}>
                    <Input placeholder="Search here..."
                        allowClear
                        addonAfter={<SearchOutlined />}
                        size="large"
                        onChange={handleSearch} />
                </div>
            </div>
            {

                salesReportData ? (!isDataChange ? <AntdTableComponent data={salesReportData} config={isMobile ? salesMobileTableConfig : salesDesktopTableConfig} /> : null) : <LoaderComponent />
            }
            {

                <div ref={cardRef}>{isMobile ? renderCardDetails : null}</div>
            }
            {
                <Modal title="Update Invoice Status"
                    okText="Approved"
                    cancelText="Rejected"
                    style={{ width: "90%" }} bodyStyle={{ display: "flex", justifyContent: "flex-end" }}
                    visible={isModalVisible} footer={null}
                    onCancel={handleReject}>
                        <Space>
                            <Button onClick={handleReject}>Rejected</Button>
                            <Button onClick={handleApproved}>Approved</Button>
                        </Space>
                    </Modal>
            }
            {
                openPdf ? <PDFDownloadLink document={<PdfDownLoader pdfInfo={pdfInfo} />} fileName={`${pdfInfo.invoice_number}_${pdfInfo.invoice_date}`}>
                    {({ blob, url, loading, error }) => {
                        if (blob && pdf_flag) {
                            blobToBase64String(blob).then(function (base64String) {
                                // success
                                service.SEND_FILE("send_invoice_data", base64String, `${pdfInfo.invoice_number}_${pdfInfo.customer_name.replaceAll(" ", "_")}(${pdfInfo.invoice_date})`)
                              }).catch(function (err) {
                                // error
                              });
                            
                            FileSaver.saveAs(blob, `${pdfInfo.invoice_number}_${pdfInfo.customer_name.replaceAll(" ", "_")}(${pdfInfo.invoice_date}).pdf`);
                            setOpenpdf(false);
                            setPdfInfo({});
                            setLoading(false);
                            pdf_flag = false;
                            
                        }
                    }

                    }
                </PDFDownloadLink> : null
            }
            {isLoading ? <div className="loading" style={{ ...style.printLoader }}>
                <img src={loader} style={{ width: "45px", height: "45px" }} />
            </div> : null}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        // customerTableData: state.customerTableData
    }
};

const mapDispatchToProps = dispatch => ({
    // setCustomerTableData: data => dispatch(service.fetchCustomerData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesReportComponent);