import React, { useEffect, useState, useRef } from "react";
import AddOrUpdateCustomer from "./AddOrUpdateCustomer";
import AntdTableComponent from './AntdTableComponent';
import { Button, Input, Tooltip, Popconfirm, Card, Dropdown, Menu, Row, Col, Typography } from "antd";
import { connect } from "react-redux";
import ACTIONS from "../redux/actions";
import service from "../common/service";
import { DeleteOutlined, CloudSyncOutlined, SearchOutlined, EllipsisOutlined } from '@ant-design/icons';
import style from "../css/common.js";
import LoaderComponent from "./LoaderComponent";
import ModalComponent from "./ModalComponent";
import AddOrUpdateProduct from "./AddOrUpdateProduct";
import { isDesktop, isMobile } from 'react-device-detect';

const { Text } = Typography;

const ProductComponent = (props) => {

    const cardRef = useRef(null);
    const [isDataChange, renderNull] = useState(false);
    const [modalOpen, setModalOpen] = useState(true);
    const [productData, setProductData] = useState([]);
    const [updateProductClicked, changeUpdateProductClick] = useState(false);
    const [cardData, changeCardData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const productDesktopTableConfig = {
        showTotalColumns: true,
        tableSize: "small",
        scroll: true,
        scrollX: 800,
        scrollY: 450,
        pageSize: 25,
        showIndex: true,
        indexTitle: "S.No",
        showPagination: true,
        pageOptions: false,
        rowSelection: true,
        selectionColumnWidth: "20px",
        rowSelectionChange: function(keys, rows) {
            setSelectedRows(keys, "test")
        },
        enableRadioButton: false,
        selectedRowKeys: "selectedFilters",
        disableRowsSelection: [],
        showTableLegend: false,
        columns: [
            {
                title: "PRODUCT CATEGORY",
                dataIndex: "product_category",
                nullText: "NA",
                sort: false,
                width: 200,
                showFilter: false,
                fixed: "left"
            },
            {
                title: "PRODUCT DIMENSION",
                dataIndex: "product_dimension",
                nullText: "NA",
                sort: false,
                width: 130,
                showFilter: false,
                fixed: "left"
            },
            {
                title: "PRODUCT DESCRIPTION",
                dataIndex: "product_description",
                nullText: "NA",
                sort: false,
                width: 350,
                showFilter: false,
            },

            {
                title: "HSN CODE",
                dataIndex: "product_hsn_code",
                nullText: "NA",
                sort: false,
                width: 100,
                showFilter: false,
            },
            {
                title: "PRICE",
                dataIndex: "product_price",
                nullText: "NA",
                sort: false,
                width: 80,
                showFilter: false,
            },
            {
                title: "OPTIONS",
                sort: false,
                width: 80,
                showFilter: false,
                fixed: "right",
                render: (text, record, index) => {
                    // console.log(text, record, index)
                    return (
                        <div className="table-options" style={{ ...style.TableIconsContainer }}>
                            <Tooltip placement="top" title={"Update"}>
                                <Button icon={<CloudSyncOutlined />} style={{ ...style.TableIcons, backgroundColor: "#00af80" }} onClick={e => handleUpdateProduct(text, record, index)} />
                            </Tooltip>
                            <Tooltip placement="top" title={"Delete"}>
                                <Popconfirm
                                    placement="topRight"
                                    title="Are you sure Want to Delete?"
                                    onConfirm={e => handleDeleteProduct([text._id], record, index)}
                                >
                                    <Button icon={<DeleteOutlined />} style={{ ...style.TableIcons }} /> </Popconfirm>

                            </Tooltip>
                        </div>
                    )
                }
            },
        ]
    }
    const productMobileTableConfig = {
        showTotalColumns: true,
        tableSize: "small",
        scroll: false,
        scrollX: (window.innerWidth - 50),
        scrollY: window.innerHeight,
        pageSize: 25,
        showIndex: false,
        indexTitle: "S.No",
        showPagination: true,
        pageOptions: false,
        rowSelection: true,
        selectionColumnWidth: "20px",
        rowSelectionChange: function(keys, rows) {
            console.log(keys, "mobole")
            setSelectedRows(keys)
        },
        enableRadioButton: false,
        selectedRowKeys: "selectedFilters",
        disableRowsSelection: [],
        showTableLegend: false,
        columns: [
            {
                title: "PRODUCT CATEGORY / PRODUCT DIMENSION",
                sort: false,
                width: 350,
                showFilter: false,
                render: (text, record, index) => {
                    return (
                        <div className="table-options">
                            <Tooltip placement="top" title={record.product_description}>
                                <div style={{ fontSize: "12px", color: "#ccc" }}>{record.product_category}</div>
                                <div style={{ fontSize: "15px" }}>{record.product_dimension}</div>
                            </Tooltip>
                        </div>
                    )
                }
            },
            {
                title: "OPTIONS",
                sort: false,
                width: 10,
                showFilter: false,
                render: (text, record, index) => {
                    return (
                        <div className="table-options" style={{ ...style.TableIconsContainer }}>
                            <Tooltip placement="top" title={"Click to View Product Details"}>
                                <Button style={{ backgroundColor: "#ffd666", border: "unset", color: "#000" }} onClick={e => handleViewDetails(text, record, index)}>View Details</Button>
                            </Tooltip>
                        </div>
                    )
                }
            },
        ]
    }
    useEffect(() => {
        props.setProductTableData();
        props.setCustomerTableData();
    }, []);

    useEffect(() => {
        let products = props.productTableData || [];
        products.forEach((product, i) => {
            product.key = product._id;
            product.product_dimension = `${product.product_length} X ${product.product_width} X ${product.product_height}`
        })
        let finalData = service.sortByKey(products, "product_category", "asc");
        setProductData(finalData);
        setModalOpen(false);
        renderNull(true)
    }, [props.productTableData])
    const addCustomerButton = () => {
        if (isMobile) {
            props.setCurrentMenu("add_product");
        } else {
            setModalOpen(!modalOpen);
        }
    }
    const handleModalClose = () => {
        setModalOpen(!modalOpen);
        changeUpdateProductClick(false)
    }

    const handleDeleteProduct = (value) => {
        service.DELETE("delete_product_data", [{ column: "_id", value: value }]).then(res => {
            props.setProductTableData();
        }).catch(err => err);
    }
    const handleUpdateProduct = (e, value, data) => {
        changeUpdateProductClick(true)
        setModalOpen(!modalOpen);
        props.updateProductDetails(e);
    }


    const handleSearch = (e) => {
        let value = e.target.value;
        let data = [...props.productTableData];
        let finalData = data.filter(m => {
            if (`${m.product_length} X ${m.product_width} X ${m.product_height}`.toLowerCase().indexOf(value.toLowerCase()) > -1 || m.product_category.toLowerCase().indexOf(value.toLowerCase()) > -1 || m.product_description.toLowerCase().indexOf(value.toLowerCase()) > -1) {
                return true
            };
        }
        );
        if (value.length) {
            finalData = service.sortByKey(finalData, "product_category", "asc");
            setProductData(finalData.length ? finalData : []);
        } else {
            finalData = service.sortByKey(props.productTableData, "product_category", "asc");
            setProductData(finalData);
        }
        changeCardData([]);
        renderNull(true);
    }

    useEffect(() => {
        renderNull(false)
    }, [productData]);

    const handleExtraMenu = (record, value) => {
        if (value.key == "update") {
            props.setCurrentMenu("update_product");
            props.updateProductDetails(record);

        } else if (value.key == "delete") {
            handleDeleteProduct([record._id]);
        }
        changeCardData([]);
    }

    const handleViewDetails = (value, record, index) => {
        let data = productData.filter(m => m._id == record._id);
        changeCardData(data);
        cardRef.current.scrollIntoView()  
    }

    const extraMenu = (m) => {
        return (
            <Menu onClick={e => handleExtraMenu(m, e)}>
                <Menu.Item key="update" icon={<CloudSyncOutlined />}>
                    <div>Update</div>
                </Menu.Item>
                <Menu.Item key="delete" icon={<DeleteOutlined />}>
                    <div>Delete</div>
                </Menu.Item>
            </Menu>
        )
    }
    const renderExtra = (m) => {
        return (
            <Dropdown overlay={extraMenu(m)} trigger={['click']} placement="bottomRight" arrow>
                <EllipsisOutlined key="ellipsis" style={{ transform: "rotate(90deg)" }} />
            </Dropdown>
        )
    }
    const renderCard = () => {
        return cardData.map((m, i) => {
            return (
                <Card
                    title={
                        <div>
                            <div>{m.product_dimension}</div>
                            <div style={{ fontSize: "11px", fontWeight: 400, color: "#ccc" }}>{m.product_category}</div>
                        </div>}
                    extra={renderExtra(m)}
                    headStyle={{ padding: "0px 10px" }}
                    bodyStyle={{ padding: "10px 10px" }}
                    style={{ margin: "10px 0px" }}
                >
                    <Row style={{ padding: "5px 0px" }}>
                        <Col span={24}>
                            <Text>{m.product_description ? m.product_description : "Sample description goes here"}</Text>
                        </Col>
                    </Row>
                    <Row style={{ padding: "5px 0px" }}>
                        <Col span={6}>
                            <Text>HSN Code:-</Text>
                        </Col>
                        <Col span={12}>
                            <Text>{m.product_hsn_code ? m.product_hsn_code : "Not Available"}</Text>
                        </Col>
                    </Row>
                    <Row style={{ padding: "5px 0px" }}>
                        <Col span={12}>
                            <Text style={{ fontSize: "25px", fontWeight: 500 }}>{m.product_price ? `₹ ${m.product_price}` : "Not Available"}</Text>
                        </Col>
                    </Row>
                </Card>
            )
        });
    }

    const renderAddProduct = () => {
        return (
            <Button style={{...style.button, width: "unset", padding: '5px 10px'}} size="small" onClick={addCustomerButton}> Add Product</Button>
        )
    }

    return (
        <div className="product-container" style={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <div>
                {
                    <div style={style.CustomerSearch}>

                        {

                            isDesktop ? renderAddProduct() : null
                        }
                        <Input placeholder="Search here..."
                            allowClear
                            addonAfter={<SearchOutlined />}
                            size="large"
                            style={{width: "25%"}}
                            onChange={handleSearch} />
                    </div>

                }
            </div>
            {
                selectedRows  && selectedRows.length ? <Button style={{backgroundColor: "red", margin: "15px 0px",color: "#fff", width: "150px"}} onClick={e => handleDeleteProduct(selectedRows)}>Delete Selected</Button> : null
            }
            {

                productData ? (!isDataChange ? <AntdTableComponent data={productData} config={isMobile ? productMobileTableConfig : productDesktopTableConfig} /> : null) : <LoaderComponent />
            }
            {

                <div ref={cardRef}>{isMobile && cardData && cardData.length ? renderCard() : null}</div>
            }
            {

                isMobile ? renderAddProduct() : null
            }
            {
                modalOpen ? <ModalComponent visible={modalOpen} onClose={handleModalClose} children={<AddOrUpdateProduct isUpdate={updateProductClicked} closePopup={handleModalClose} />} title={updateProductClicked ? "Update Product Details" : "Add Product Details"} /> : null

            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        productTableData: state.productTableData
    }
};

const mapDispatchToProps = dispatch => ({
    updateProductDetails: data => dispatch(ACTIONS.updateProductDetails(data)),
    setCurrentMenu: data => dispatch(ACTIONS.setCurrentMenu(data)),
    setProductTableData: data => dispatch(service.fetchProductData(data)),
    setCustomerTableData: data => dispatch(service.fetchCustomerData(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductComponent);