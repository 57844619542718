import React, { useEffect, useState } from "react";
import mainLogo from "../images/peak_wood.png";
import titleLogo from "../images/peak_packers_logo.jpg";
import { Menu, Dropdown, Drawer } from 'antd';
import { connect } from "react-redux";
import ACTIONS from "../redux/actions";
import { BrowserRouter as Router, Link } from "react-router-dom";
import style from "../css/common.js";
import { UploadOutlined, MenuOutlined, CloseOutlined } from '@ant-design/icons';
import service from "../common/service";
import { isDesktop, isMobile } from 'react-device-detect';

const Header = (props) => {

    const [currentMenu, setCurrentMenu] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false)
    const handleLogOut = (item) => {
        console.log(item)
        if (item.key == "logout") {
            localStorage.removeItem("user");
            props.setUserDetails({});
            props.setCurrentMenu("login");
            service.navigate("/login");
        }

    }

    const menu = (
        <Menu onClick={handleLogOut}>
            <Menu.Item key="logout" icon={<UploadOutlined />}>
                <div>Logout</div>
            </Menu.Item>
        </Menu>
    );
    useEffect(() => {
        props.setCurrentMenu(props.selectedMenu)
        setCurrentMenu(props.selectedMenu == "/" ? ["invoice"] : [props.selectedMenu]);
        service.navigate(`/${props.selectedMenu}`);
    }, [props.selectedMenu]);

    useEffect(() => {
        props.setCurrentMenu(props.selectedMenu)
        setCurrentMenu(props.selectedMenu == "/" ? ["invoice"] : [props.selectedMenu]);
        service.navigate(`/${props.selectedMenu}`);
    }, []);

    const handleClick = e => {
        if(e.key == "update_customer") {
            props.updateCustomerDetails({});
            props.updateCustomerDetailsFrom("Menu");
        } else {
            props.updateCustomerDetailsFrom("From Data");
        }

        if(e.key == "update_product") {
            props.updateProductDetailsFrom("Menu");
        } else {
            props.updateProductDetailsFrom("From Data");
        }
        props.setCurrentMenu(e.key);
        setCurrentMenu([e.key]);
        setMenuOpen(false)
    };

    const handleMenuOpen = () => {
        setMenuOpen(true)
    }
    const handleMenuClose = () => {
        setMenuOpen(false)
    }

    const renderMenuTitle = () => {
        return (
            <div className="user-details" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                <div style={{ width: "100%", padding: "5px 10px" }}>
                    <span style={{ ...style.ConvertToCapitalCase, fontSize: "15px", fontWeight: "600" }}>{props.user && props.user.username}</span>
                </div>
                <img src={mainLogo} style={{ width: "45px", height: "45px" }} />
            </div>
        )
    }

    const renderMenuFooter = () => {
        return (
            <span style={{ width: "100%", fontSize: "11px", fontWeight: 500, color: "#000", display: "flex", justifyContent: "center", cursor: "pointer" }} onClick={handleLogOut}>Logout</span>
        )
    }

    const renderMenus = () => {
        let menus = [{ value: "invoice", label: "Invoice", showMenu: true, }, { value: "purchase", label: "Purchase", showMenu: true, }, { value: "customers", label: "Customers", showMenu: true }, { value: "add_customer", label: "Add Customer", showMenu: false }, { value: "update_customer", label: "Update Customers", showMenu: false },
        { value: "products", label: "Products", showMenu: true }, { value: "add_product", label: "Add Product", showMenu: false }, { value: "update_product", label: "Update Product", showMenu: false }, { value: "sales", label: "Sales Report", showMenu: true }];

        
        let filteredMenus = menus.filter(m => m.showMenu)
        let menuItems = filteredMenus.map((menu, i) => {
            return (
                <Menu.Item key={menu.value}>
                    <Link to={`/${menu.value}`}>{menu.label}</Link>
                </Menu.Item>
            )
        });
        return (
            <div style={{flex: 1}}>
                <Router>
                <Menu mode={isMobile ? "inline" : "horizontal"} selectedKeys={currentMenu} onClick={handleClick}>
                    {
                        menuItems
                    }
                </Menu>
            </Router>
            </div>
        )
    }

    return (
        <div className="header-container" style={{ width: "100%", marginBottom: "12px"}}>
            <div className="header-top-container" style={{ display: "flex" }}>
                <div className="main-logo" style={{ display: "flex", justifyContent: "flex-start" , flex: 1}}>
                    <img src={titleLogo} style={{ width: isMobile ? "80%" : "45%" }} />
                </div>
                {isDesktop ? renderMenus() : null}
                <div className="user-details" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {isMobile ? <MenuOutlined style={{ fontSize: "20px" }} onClick={handleMenuOpen} /> : <div style={{ display: "flex" }}>
                        {/* <span style={{ ...style.ConvertToCapitalCase, padding: "12px", fontSize: "15px", fontWeight: "400" }}>{props.user && props.user.username}</span> */}
                        <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" arrow>
                            <img src={mainLogo} style={{ width: "40px", height: "40px" }} />
                        </Dropdown>
                    </div>
                    }


                </div>
            </div>
            <div className="menu-container">
                {isMobile ? <Drawer
                    title={renderMenuTitle()}
                    headerStyle={{ padding: "5px" }}
                    footer={renderMenuFooter()}
                    footerStyle={{ backgroundColor: "#ffd666" }}
                    placement={"left"}
                    closable={false}
                    onClose={handleMenuClose}
                    visible={menuOpen}
                    key="left"
                    bodyStyle={{ padding: "5px" }}
                    style={{ height: window.innerHeight }}
                >
                    {renderMenus()}
                </Drawer> : null}

            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        selectedMenu: state.selectedMenu,
        user: state.user
    }
};

const mapDispatchToProps = dispatch => ({
    updateCustomerDetails: data => dispatch(ACTIONS.updateCustomerDetails(data)),
    updateCustomerDetailsFrom: data => dispatch(ACTIONS.updateCustomerDetailsFrom(data)),
    updateProductDetailsFrom: data => dispatch(ACTIONS.updateProductDetailsFrom(data)),
    setCurrentMenu: data => dispatch(ACTIONS.setCurrentMenu(data)),
    setUserDetails: data => dispatch(ACTIONS.setUserDetails(data)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Header);